import * as React from 'react';

export function EmailMagicLinksIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 9.75a3.75 3.75 0 005.656.405l2.25-2.25a3.75 3.75 0 00-5.303-5.303l-1.29 1.283m1.688 4.365a3.75 3.75 0 00-5.655-.405l-2.25 2.25a3.75 3.75 0 005.302 5.303l1.283-1.283"
        stroke="url(#prefix__paint0_linear_729_27318)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27318"
          x1={5.001}
          y1={2}
          x2={14.501}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
