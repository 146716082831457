import * as React from 'react';

export function SAMLIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.75 5.75c0-.384-.146-.768-.44-1.06a1.495 1.495 0 00-1.06-.44m0 6a4.5 4.5 0 10-4.46-3.895c.044.326.066.489.051.592a.64.64 0 01-.087.26c-.051.09-.14.18-.32.359l-3.833 3.833c-.13.13-.194.194-.24.27a.75.75 0 00-.09.217c-.021.086-.021.178-.021.361v1.303c0 .42 0 .63.082.79a.75.75 0 00.328.328c.16.082.37.082.79.082h1.8v-1.5h1.5v-1.5h1.5l1.184-1.184c.18-.18.269-.269.36-.32a.64.64 0 01.26-.087c.102-.015.265.007.59.05.199.027.4.041.606.041z"
        stroke="url(#prefix__paint0_linear_729_27345)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27345"
          x1={1}
          y1={1}
          x2={15}
          y2={15}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
