import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { sections } from './content';
import { NavItemWithIconMobile } from '../nav-item-mobile';
import { APIIcon, ComponentIcon } from '@components/common';

const TopButtons = (): JSX.Element | null => {
  return (
    <VStack>
      <Box
        h="85px"
        w="full"
        mr={2}
        borderRadius={'8px'}
        boxShadow={'0px 4px 10px rgba(0, 153, 255, 0.2)'}
        transition="background .2s"
        _hover={{
          bg: 'gray.25'
        }}
      >
        <NavItemWithIconMobile
          label={'Custom flows'}
          href={'/docs/authentication/custom-flows'}
          icon={APIIcon}
          description="APIs, SDKs, and React hooks."
          iconBoxBg="gray.25"
          iconColor="linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)"
          iconSize="18px"
          withArrow
        />
      </Box>
    </VStack>
  );
};

interface Props {
  setSelected?: (selected: string) => void;
}

export function ProductMobile({ setSelected }: Props): JSX.Element | null {
  return (
    <>
      <HStack
        display={'flex'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        overflow={'hidden'}
      >
        <Flex
          bg="#F7F9FC"
          flexDir="column"
          py="24px"
          pos="relative"
          borderRadius="10px 10px 10px 10px"
          gap={4}
          alignSelf={'center'}
        >
          {sections.map((section, index) => {
            return (
              <Flex
                key={index} // Adding 'key' prop here
                justifyContent={'flex-start'}
                alignItems={'center'}
                onClick={() => {
                  if (setSelected) {
                    setSelected(section.name as string);
                  }
                }}
                w="90vw"
              >
                <NavItemWithIconMobile
                  label={
                    section.name == 'Authentication & Users'
                      ? 'Auth & Users'
                      : section.name == 'Organizations (for B2B SaaS)'
                      ? 'Organizations'
                      : section.name
                  }
                  icon={section.icon}
                  description={section.description}
                  iconBoxBg="gray.25"
                  iconColor="linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)"
                  iconSize="18px"
                  withArrow
                />
              </Flex>
            );
          })}
        </Flex>
      </HStack>
      <Box>
        <TopButtons />
      </Box>
    </>
  );
}
