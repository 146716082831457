import { Stack, Flex, Heading, Box, Center } from '@chakra-ui/react';

import { NavItemWithIcon } from '../nav-item-with-icon';
import { sections } from './content';

export function Orgs(): JSX.Element {
  return (
    <Stack w="100vw" pos="relative" gap={6} px={6}>
      <Heading fontSize={'md'}>Organizations (for B2B SaaS)</Heading>

      <Flex
        pos="relative"
        flexDir={'column'}
        gap="12px"
        justifyContent={'flex-start'}
        w="full"
      >
        {sections[2].features.map((feature, index) => (
          <Box key={index}>
            <NavItemWithIcon
              label={feature.label}
              href={''}
              icon={feature.icon}
              iconBoxBg="gray.25"
              iconColor="linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)"
              iconSize="18px"
            />
          </Box>
        ))}
      </Flex>
    </Stack>
  );
}
