export function OrgProfileIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 16.363c.452.137 1.062.137 2.1.137h7.8c1.038 0 1.648 0 2.1-.137m-12 0a1.647 1.647 0 01-.271-.108 2.25 2.25 0 01-.984-.983C1.5 14.79 1.5 14.16 1.5 12.9V5.1c0-1.26 0-1.89.245-2.371a2.25 2.25 0 01.984-.984C3.209 1.5 3.839 1.5 5.1 1.5h7.8c1.26 0 1.89 0 2.371.245.424.216.768.56.984.984.245.48.245 1.11.245 2.371v7.8c0 1.26 0 1.89-.245 2.371-.216.424-.56.768-.983.984a1.647 1.647 0 01-.272.108m-12 0c0-.607.004-.928.058-1.198a3 3 0 012.357-2.357c.29-.058.638-.058 1.335-.058h4.5c.697 0 1.046 0 1.335.058a3 3 0 012.357 2.357c.054.27.058.591.058 1.198m-3-9.238a3 3 0 11-6 0 3 3 0 016 0z"
        stroke="url(#prefix__paint0_linear_729_27325)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27325"
          x1={0.5}
          y1={-0.5}
          x2={19.5}
          y2={18.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
