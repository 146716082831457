import * as React from 'react';

export function AuthatEdgeIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.307 5.612L5.523 7.91c.167.12.251.18.339.193.077.012.156 0 .225-.035.08-.04.141-.121.265-.286l.68-.906c.034-.047.052-.07.072-.09a.375.375 0 01.06-.047.785.785 0 01.104-.051l2.901-1.29c.12-.053.18-.08.226-.122a.376.376 0 00.092-.132c.024-.057.028-.123.037-.254l.213-3.187m-.612 8.423l1.962.84c.228.098.341.147.402.228a.375.375 0 01.073.247c-.006.101-.075.204-.212.41l-.922 1.383a.82.82 0 01-.14.182.375.375 0 01-.13.069c-.053.016-.112.016-.23.016H9.433c-.155 0-.233 0-.299-.026a.376.376 0 01-.15-.108c-.046-.055-.07-.129-.12-.276l-.534-1.604c-.03-.087-.044-.13-.048-.175a.375.375 0 01.009-.117.803.803 0 01.071-.166l.406-.813c.083-.165.124-.247.188-.298a.375.375 0 01.195-.081c.081-.009.169.02.344.079l.631.21zM16.5 9a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
        stroke="url(#prefix__paint0_linear_729_27324)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27324"
          x1={4}
          y1={1}
          x2={15.5}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
