export function RemixIcon(props) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5608 11.254C12.6643 12.6405 12.6643 13.2905 12.6643 14H9.58723C9.58723 13.8455 9.5901 13.704 9.5925 13.561C9.60113 13.115 9.60976 12.6505 9.54023 11.712C9.44912 10.3385 8.88137 10.033 7.83792 10.033H3.00048V7.533H7.98561C9.30335 7.533 9.96221 7.1155 9.96221 6.0085C9.96221 5.0355 9.30335 4.446 7.98561 4.446H3V2H8.53419C11.5173 2 13 3.469 13 5.816C13 7.5715 11.9566 8.716 10.5477 8.907C11.7369 9.1555 12.4322 9.8615 12.5608 11.254ZM3 14V12.1365H6.25309C6.79639 12.1365 6.91436 12.5565 6.91436 12.8075V14H3Z"
        fill="black"
      />
    </svg>
  );
}
