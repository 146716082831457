export function InvitationsIcon(props) {
  return (
    <svg
      width={18}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.308 1.975l5.646 3.67c.2.13.3.195.372.281a.75.75 0 01.14.26c.034.108.034.227.034.465v5.499c0 1.26 0 1.89-.245 2.371a2.25 2.25 0 01-.983.984c-.482.245-1.112.245-2.372.245H5.1c-1.26 0-1.89 0-2.371-.245a2.25 2.25 0 01-.984-.984c-.245-.48-.245-1.11-.245-2.371V6.651c0-.238 0-.357.033-.464a.75.75 0 01.141-.26c.073-.087.173-.152.372-.282l5.646-3.67m2.616 0c-.473-.308-.71-.461-.965-.521a1.5 1.5 0 00-.686 0c-.255.06-.492.213-.965.521m2.616 0l5.418 3.522c.258.168.387.251.432.358.039.093.039.197 0 .29-.045.107-.174.19-.432.358l-5.418 3.522c-.473.308-.71.461-.965.521a1.5 1.5 0 01-.686 0c-.255-.06-.492-.213-.965-.521L2.274 6.503c-.258-.168-.387-.251-.432-.358a.375.375 0 010-.29c.045-.107.174-.19.432-.358l5.418-3.522"
        stroke="url(#prefix__paint0_linear_729_27342)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27342"
          x1={2}
          y1={1}
          x2={17}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
