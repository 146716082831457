import { Stack } from '@chakra-ui/react';
import { NavItemWithIcon } from '../nav-item-with-icon';

const links = [
  {
    label: 'For Startups',
    description: 'Launch quickly, with up to 6 months free.',
    icon: () => (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 11.25L6.75 9M9 11.25C10.0476 10.8516 11.0527 10.349 12 9.75M9 11.25V15C9 15 11.2725 14.5875 12 13.5C12.81 12.285 12 9.75 12 9.75M6.75 9C7.14911 7.96458 7.65165 6.97205 8.25 6.0375C9.12389 4.64024 10.3407 3.48979 11.7848 2.69557C13.2288 1.90135 14.852 1.48978 16.5 1.5C16.5 3.54 15.915 7.125 12 9.75M6.75 9H3C3 9 3.4125 6.7275 4.5 6C5.715 5.19 8.25 6 8.25 6M3.375 12.375C2.25 13.32 1.875 16.125 1.875 16.125C1.875 16.125 4.68 15.75 5.625 14.625C6.1575 13.995 6.15 13.0275 5.5575 12.4425C5.26598 12.1643 4.88197 12.0035 4.47917 11.991C4.07637 11.9786 3.68316 12.1153 3.375 12.375Z"
          stroke="url(#paint0_linear_609_24979)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_609_24979"
            x1="2"
            y1="3.50006"
            x2="14"
            y2="16.0001"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6C47FF" />
            <stop offset="1" stopColor="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    ),
    href: '/startups'
  },
  {
    label: 'Customer Stories',
    description: 'Learn about the ways our customers use Clerk.',
    icon: () => (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.625 7.875H5.6325M9 7.875H9.0075M12.375 7.875H12.3825M5.25 13.5V15.2516C5.25 15.6513 5.25 15.8511 5.33192 15.9537C5.40317 16.043 5.5112 16.0949 5.6254 16.0948C5.75672 16.0946 5.91275 15.9698 6.22482 15.7201L8.01391 14.2889C8.37939 13.9965 8.56213 13.8503 8.76561 13.7463C8.94615 13.6541 9.13832 13.5867 9.33691 13.5459C9.56075 13.5 9.79477 13.5 10.2628 13.5H12.15C13.4101 13.5 14.0402 13.5 14.5215 13.2548C14.9448 13.039 15.289 12.6948 15.5048 12.2715C15.75 11.7902 15.75 11.1601 15.75 9.9V5.85C15.75 4.58988 15.75 3.95982 15.5048 3.47852C15.289 3.05516 14.9448 2.71095 14.5215 2.49524C14.0402 2.25 13.4101 2.25 12.15 2.25H5.85C4.58988 2.25 3.95982 2.25 3.47852 2.49524C3.05516 2.71095 2.71095 3.05516 2.49524 3.47852C2.25 3.95982 2.25 4.58988 2.25 5.85V10.5C2.25 11.1975 2.25 11.5462 2.32667 11.8323C2.53472 12.6088 3.1412 13.2153 3.91766 13.4233C4.20378 13.5 4.55252 13.5 5.25 13.5ZM6 7.875C6 8.08211 5.83211 8.25 5.625 8.25C5.41789 8.25 5.25 8.08211 5.25 7.875C5.25 7.66789 5.41789 7.5 5.625 7.5C5.83211 7.5 6 7.66789 6 7.875ZM9.375 7.875C9.375 8.08211 9.20711 8.25 9 8.25C8.79289 8.25 8.625 8.08211 8.625 7.875C8.625 7.66789 8.79289 7.5 9 7.5C9.20711 7.5 9.375 7.66789 9.375 7.875ZM12.75 7.875C12.75 8.08211 12.5821 8.25 12.375 8.25C12.1679 8.25 12 8.08211 12 7.875C12 7.66789 12.1679 7.5 12.375 7.5C12.5821 7.5 12.75 7.66789 12.75 7.875Z"
          stroke="url(#paint0_linear_609_24993)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_609_24993"
            x1="2"
            y1="2"
            x2="16"
            y2="16"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6C47FF" />
            <stop offset="1" stopColor="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    ),
    href: '/customers'
  }
];

export function CustomersMobile(): JSX.Element | null {
  return (
    <>
      <Stack gap={6} w="90vw" pos="relative">
        {links.map(({ label, icon, href }) => (
          <NavItemWithIcon key={label} label={label} icon={icon} href={href} />
        ))}
      </Stack>
    </>
  );
}
