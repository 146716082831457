import * as React from 'react';

export function SignInIcon(props) {
  return (
    <svg
      width={18}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.25 14.75l2.25-2.25m0 0l-2.25-2.25m2.25 2.25H12m-3-1.875H5.625c-1.047 0-1.57 0-1.996.13a3 3 0 00-2 2c-.129.425-.129.948-.129 1.995m9.375-10.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0z"
        stroke="url(#prefix__paint0_linear_729_27331)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27331"
          x1={3}
          y1={0}
          x2={17.5}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
