import * as React from 'react';

export function EmailSMSIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.625 6.875h.008m3.367 0h.008m3.367 0h.008M4.25 12.5v1.752c0 .4 0 .6.082.702.071.089.18.14.293.14.132 0 .288-.124.6-.374l1.789-1.431c.365-.293.548-.439.752-.543.18-.092.372-.16.57-.2.225-.046.459-.046.927-.046h1.887c1.26 0 1.89 0 2.371-.245.424-.216.768-.56.984-.984.245-.48.245-1.11.245-2.371V4.85c0-1.26 0-1.89-.245-2.371a2.25 2.25 0 00-.984-.984c-.48-.245-1.11-.245-2.371-.245h-6.3c-1.26 0-1.89 0-2.371.245a2.25 2.25 0 00-.984.984c-.245.48-.245 1.11-.245 2.371V9.5c0 .697 0 1.046.077 1.332a2.25 2.25 0 001.59 1.591c.287.077.636.077 1.333.077zM5 6.875a.375.375 0 11-.75 0 .375.375 0 01.75 0zm3.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm3.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
        stroke="url(#prefix__paint0_linear_729_27322)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27322"
          x1={1}
          y1={1}
          x2={15}
          y2={15}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
