export function LoginIcon(props) {
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-theme="light"
    focusable="false"
    class="chakra-icon css-s48i3l"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M12 10.7151L11.3888 11.4179C11.0647 11.7906 10.6251 12 10.1668 12C9.70841 12 9.26883 11.7906 8.94469 11.4179C8.62009 11.046 8.18055 10.8372 7.72234 10.8372C7.26413 10.8372 6.82459 11.046 6.49999 11.4179M1 12H2.02333C2.32228 12 2.47175 12 2.61241 11.9645C2.73712 11.933 2.85635 11.8811 2.9657 11.8106C3.08904 11.7312 3.19474 11.6201 3.40612 11.3978L11.0834 3.3266C11.5896 2.79436 11.5896 1.93142 11.0834 1.39918C10.5771 0.866939 9.75629 0.86694 9.25003 1.39918L1.57277 9.47041C1.36138 9.69264 1.25569 9.80376 1.18011 9.93343C1.11309 10.0484 1.06371 10.1737 1.03377 10.3049C1 10.4527 1 10.6099 1 10.9242V12Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>;
}
