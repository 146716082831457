import { Text, Box, Flex, Stack, Icon, HStack, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import { NavItemWithIcon } from '../nav-item-with-icon';
import { NavItemTextOnly } from '../nav-item-text-only';
import { BookOpenIcon } from '@components/common/icons';
import Link from 'next/link';

import { DevelopersMobile } from '../developers/developers-mobile';
import {
  NextJSIcon,
  GatsbyIcon,
  ReactIcon,
  ExpoIcon,
  HasuraIcon,
  FirebaseIcon,
  SupabaseIcon,
  RedwoodIcon,
  FaunaIcon,
  RemixIcon,
  GrafbaseIcon
} from '../../../common/icons';

const integrations = [
  {
    label: 'NextJS',
    href: '/docs/nextjs/overview',
    icon: () => <Icon as={NextJSIcon} />
  },
  {
    label: 'Remix',
    href: '/docs/quickstarts/get-started-with-remix',
    icon: () => <Icon as={RemixIcon} />
  },
  {
    label: 'React',
    href: '/docs/quickstarts/get-started-with-create-react-app',
    icon: () => <Icon as={ReactIcon} />
  },
  {
    label: 'RedwoodJS',
    href: '/docs/quickstarts/get-started-with-redwoodjs',
    icon: () => <Icon as={RedwoodIcon} />
  },
  {
    label: 'Gatsby',
    href: '/docs/quickstarts/get-started-with-gatsby',
    icon: () => <Icon as={GatsbyIcon} />
  },
  {
    label: 'React Native / Expo',
    href: '/docs/quickstarts/get-started-with-expo',
    icon: () => <Icon as={ExpoIcon} />
  }
];

const databases = [
  {
    label: 'Hasura',
    href: '/integrations/hasura',
    icon: () => <Icon as={HasuraIcon} />
  },
  {
    label: 'Supabase',
    href: '/integrations/supabase',
    icon: () => <Icon as={SupabaseIcon} />
  },
  {
    label: 'Fauna',
    href: '/integrations/fauna',
    icon: () => <Icon as={FaunaIcon} />
  },
  {
    label: 'Firebase',
    href: '/integrations/firebase',
    icon: () => <Icon as={FirebaseIcon} />
  },
  {
    label: 'Grafbase',
    href: '/integrations/grafbase',
    icon: () => <Icon as={GrafbaseIcon} />
  }
];

const learn = [
  {
    label: 'Tutorials',
    href: '/tutorials',
    icon: () => (
      <svg
        width="15"
        height="12"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.125 4.74998V8.50695C3.125 8.73129 3.125 8.84347 3.15916 8.9425C3.18937 9.03008 3.23867 9.10985 3.30349 9.17603C3.37679 9.25087 3.47713 9.30104 3.67779 9.40137L7.05279 11.0889C7.21676 11.1709 7.29875 11.2119 7.38475 11.228C7.46092 11.2423 7.53908 11.2423 7.61525 11.228C7.70125 11.2119 7.78324 11.1709 7.94721 11.0889L11.3222 9.40137C11.5229 9.30104 11.6232 9.25088 11.6965 9.17603C11.7613 9.10985 11.8106 9.03008 11.8408 8.9425C11.875 8.84347 11.875 8.73129 11.875 8.50695V4.74998M1.25 3.81248L7.27639 0.799283C7.35838 0.758289 7.39938 0.737791 7.44238 0.729724C7.48046 0.722579 7.51954 0.722579 7.55762 0.729724C7.60062 0.737791 7.64162 0.758289 7.72361 0.799283L13.75 3.81248L7.72361 6.82568C7.64162 6.86667 7.60062 6.88717 7.55762 6.89524C7.51954 6.90238 7.48046 6.90238 7.44238 6.89524C7.39938 6.88717 7.35838 6.86667 7.27639 6.82568L1.25 3.81248Z"
          stroke="url(#paint0_linear_1768_29514)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1768_29514"
            x1="3"
            y1="1"
            x2="14"
            y2="11"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'Public Roadmap',
    href: 'https://clerkdev.notion.site/019192f70e864d40a44870102cf2176f?v=c594d282a5f544b39aa0612842e9fd6b',
    icon: () => (
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.625 10.75L1.25 13.25V3.25L5.625 0.75M5.625 10.75L10 13.25M5.625 10.75V0.75M10 13.25L13.75 10.75V0.75L10 3.25M10 13.25V3.25M10 3.25L5.625 0.75"
          stroke="url(#paint0_linear_1768_29515)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1768_29515"
            x1="1"
            y1="1"
            x2="15.5"
            y2="13"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'Support',
    href: '/support',
    icon: () => (
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.49572 2.70988C6.24612 1.249 4.16234 0.856027 2.59668 2.19376C1.03103 3.53149 0.810605 5.76811 2.04012 7.35025C3.06239 8.66569 6.15611 11.4401 7.17006 12.338C7.2835 12.4385 7.34022 12.4887 7.40638 12.5085C7.46413 12.5257 7.52731 12.5257 7.58506 12.5085C7.65122 12.4887 7.70794 12.4385 7.82138 12.338C8.83533 11.4401 11.9291 8.66569 12.9513 7.35025C14.1808 5.76811 13.9873 3.51742 12.3948 2.19376C10.8022 0.870098 8.74532 1.249 7.49572 2.70988Z"
          stroke="url(#paint0_linear_1768_29517)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1768_29517"
            x1="1"
            y1="1.5"
            x2="15.5"
            y2="14"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'API & SDK Reference',
    href: '/docs/reference/overview',
    icon: () => (
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.75 6.375H3M4.25 8.875H3M8 3.875H3M10.5 6.0625V3.75C10.5 2.6999 10.5 2.17485 10.2956 1.77377C10.1159 1.42096 9.82904 1.13413 9.47623 0.954363C9.07515 0.75 8.5501 0.75 7.5 0.75H3.5C2.4499 0.75 1.92485 0.75 1.52377 0.954363C1.17096 1.13413 0.884125 1.42096 0.704363 1.77377C0.5 2.17485 0.5 2.6999 0.5 3.75V10.25C0.5 11.3001 0.5 11.8251 0.704363 12.2262C0.884125 12.579 1.17096 12.8659 1.52377 13.0456C1.92485 13.25 2.4499 13.25 3.5 13.25H5.1875M11.75 13.25L10.8125 12.3125M11.4375 10.75C11.4375 11.9581 10.4581 12.9375 9.25 12.9375C8.04188 12.9375 7.0625 11.9581 7.0625 10.75C7.0625 9.54188 8.04188 8.5625 9.25 8.5625C10.4581 8.5625 11.4375 9.54188 11.4375 10.75Z"
          stroke="url(#paint0_linear_1768_29518)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1768_29518"
            x1="1"
            y1="0.5"
            x2="14.5"
            y2="15.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'API Status',
    href: 'https://status.clerk.dev/',
    icon: () => (
      <svg
        width="15"
        height="10"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1517 2.34833C11.6161 3.8128 11.6161 6.18717 10.1517 7.65163M4.84835 7.65161C3.38388 6.18715 3.38388 3.81278 4.84835 2.34831M3.08058 9.4194C0.639806 6.97862 0.639806 3.02134 3.08058 0.580566M11.9194 0.580595C14.3602 3.02137 14.3602 6.97865 11.9194 9.41943M8.75 4.99998C8.75 5.69034 8.19036 6.24998 7.5 6.24998C6.80964 6.24998 6.25 5.69034 6.25 4.99998C6.25 4.30963 6.80964 3.74998 7.5 3.74998C8.19036 3.74998 8.75 4.30963 8.75 4.99998Z"
          stroke="url(#paint0_linear_1768_29519)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1768_29519"
            x1="1"
            y1="-1"
            x2="15"
            y2="10.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'Community',
    href: 'https://discord.com/invite/b5rXHjAg7A',
    icon: () => (
      <Image src="/images/discord.png" width={15} height={10} alt="" />
    )
  }
];

interface Props {
  mobileView?: boolean;
}

export function Developers({ mobileView }: Props): JSX.Element {
  if (mobileView) {
    return <DevelopersMobile />;
  } else {
    return (
      <Flex w="600px" bg="white" flexDir="column" borderRadius={'12px'}>
        <Stack flex={1} spacing={7} p="16px">
          <NavItemWithIcon
            label="Documentation"
            description="Start integrating Clerk’s products and tools."
            icon={BookOpenIcon}
            iconBoxBg="linear-gradient(128.61deg, #6C47FF 0%, #17CCFC 152.52%)"
            href="/docs"
            iconColor="gray.100"
            noHover
          />

          <Flex
            w="full"
            flexDir="row"
            pl={1}
            pb="12px"
            justifyContent={'center'}
            alignItems="center"
            gap={6}
          >
            <Box flex={1}>
              <Text
                fontFamily="body"
                fontStyle="normal"
                fontWeight={700}
                fontSize="14px"
                lineHeight="16px"
                letterSpacing="-0.02em"
                color="#292C33"
                flex="none"
                order={0}
                flexGrow={0}
                zIndex={100}
                px={2}
                _groupHover={{ color: 'primary.500' }}
              >
                Framework Integrations
              </Text>

              <HStack px={2} pt={4}>
                <VStack w="full" alignItems="flex-start">
                  {integrations.slice(0, 3).map(({ label, href, icon }) => (
                    <NavItemTextOnly
                      key={label}
                      label={label}
                      href={href}
                      icon={icon}
                    />
                  ))}
                </VStack>
                <VStack
                  w="full"
                  justifyContent={'flex-start'}
                  alignItems="flex-start"
                >
                  {integrations
                    .slice(3, integrations.length)
                    .map(({ label, href, icon }) => (
                      <NavItemTextOnly
                        key={label}
                        label={label}
                        href={href}
                        icon={icon}
                      />
                    ))}
                </VStack>
              </HStack>
            </Box>

            <Box flex={1}>
              <Text
                fontFamily="body"
                fontStyle="normal"
                fontWeight={700}
                fontSize="14px"
                lineHeight="16px"
                letterSpacing="-0.02em"
                color="#292C33"
                flex="none"
                order={0}
                flexGrow={0}
                zIndex={100}
                px={3}
                _groupHover={{ color: 'primary.500' }}
              >
                Third Party Integrations
              </Text>

              <HStack px={4} spacing={0} pt={4}>
                <VStack w="full" alignItems="flex-start">
                  {databases.slice(0, 3).map(({ label, href, icon }) => (
                    <NavItemTextOnly
                      key={label}
                      label={label}
                      href={href}
                      icon={icon}
                    />
                  ))}
                </VStack>
                <VStack
                  w="full"
                  justifyContent={'flex-start'}
                  alignItems="flex-start"
                >
                  {databases
                    .slice(3, databases.length)
                    .map(({ label, href, icon }) => (
                      <NavItemTextOnly
                        key={label}
                        label={label}
                        href={href}
                        icon={icon}
                      />
                    ))}
                  <Link
                    legacyBehavior
                    href={'/docs/integration/overview'}
                    passHref
                  >
                    <Flex flexDir={'row'} alignItems={'center'}>
                      <Icon
                        as={() => (
                          <svg
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.37512 8.87503V5.12503M8.37512 5.12503H4.62512M8.37512 5.12503L4.62512 8.87496M3.875 12.625H9.125C10.1751 12.625 10.7001 12.625 11.1012 12.4206C11.454 12.2409 11.7409 11.954 11.9206 11.6012C12.125 11.2001 12.125 10.6751 12.125 9.625V4.375C12.125 3.3249 12.125 2.79985 11.9206 2.39877C11.7409 2.04596 11.454 1.75913 11.1012 1.57936C10.7001 1.375 10.1751 1.375 9.125 1.375H3.875C2.8249 1.375 2.29985 1.375 1.89877 1.57936C1.54596 1.75913 1.25913 2.04596 1.07936 2.39877C0.875 2.79985 0.875 3.3249 0.875 4.375V9.625C0.875 10.6751 0.875 11.2001 1.07936 11.6012C1.25913 11.954 1.54596 12.2409 1.89877 12.4206C2.29985 12.625 2.8249 12.625 3.875 12.625Z"
                              stroke="#6C47FF"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                        boxSize={6}
                      />
                      <Text
                        cursor={'pointer'}
                        fontFamily="body"
                        fontStyle="normal"
                        fontWeight={400}
                        fontSize="14px"
                        lineHeight="24px"
                        letterSpacing="-0.02em"
                        flex="none"
                        ml={2}
                        order={0}
                        flexGrow={0}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap="8px"
                        whiteSpace={'nowrap'}
                        color="primary.500"
                        _hover={{ color: 'primary.700' }}
                      >
                        View all
                      </Text>
                    </Flex>
                  </Link>
                </VStack>
              </HStack>
            </Box>
          </Flex>
        </Stack>

        <Flex
          bg="#F7F9FC"
          rounded="lg"
          p={4}
          w="full"
          pos="relative"
          left={'0'}
          flexDir="row"
          justifyContent={'space-between'}
          h="80px"
        >
          <HStack
            pos="relative"
            display="flex"
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={'12px'}
            py="8px"
            flex={1}
          >
            <Flex flexDir="column" w="full" pl="12px">
              {learn.slice(0, 2).map(({ label, href, icon }) => (
                <NavItemTextOnly
                  key={label}
                  label={label}
                  href={href}
                  icon={icon}
                  py="4px"
                />
              ))}
            </Flex>
            <Flex flexDir="column" w="full">
              {learn.slice(2, 4).map(({ label, href, icon }) => (
                <NavItemTextOnly
                  key={label}
                  label={label}
                  href={href}
                  icon={icon}
                  py="4px"
                />
              ))}{' '}
            </Flex>
            <Flex flexDir="column" w="full">
              {learn.slice(4, learn.length).map(({ label, href, icon }) => (
                <NavItemTextOnly
                  key={label}
                  label={label}
                  href={href}
                  icon={icon}
                  py="4px"
                />
              ))}
            </Flex>
          </HStack>
        </Flex>
      </Flex>
    );
  }
}
