import Link from 'next/link';
import { Text, Flex, Icon } from '@chakra-ui/react';
import { SVGProps } from 'react';

type Props = {
  label: string;
  href: string;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  py?: string;
  px?: string;
  hover?: boolean;
};

export function NavItemTextOnly({
  label,
  href,
  icon,
  py,
  px,
  hover
}: Props): JSX.Element {
  return (
    <Link legacyBehavior href={href} passHref>
      <Flex
        flexDir={'row'}
        alignItems={'center'}
        justifyContent="flex-start"
        w={'full'}
        px={px}
        py={py}
        rounded="10px"
        role="group"
        transition="background .2s"
        _hover={hover ? { bg: 'gray.25' } : {}}
      >
        {icon && <Icon as={icon} boxSize={6} />}
        <Text
          cursor={'pointer'}
          fontFamily="body"
          fontStyle="normal"
          fontWeight={400}
          fontSize="14px"
          lineHeight="24px"
          letterSpacing="-0.02em"
          flex="none"
          ml={2}
          order={0}
          flexGrow={0}
          display="flex"
          flexDirection="row"
          alignItems="center"
          py={py}
          gap="8px"
          whiteSpace={'nowrap'}
          _hover={{ color: 'primary.500' }}
        >
          {label}
        </Text>
      </Flex>
    </Link>
  );
}
