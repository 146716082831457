import React, { SVGProps, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Link from 'next/link';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import {
  Box,
  CloseButton,
  Flex,
  HStack,
  VisuallyHidden,
  Stack,
  Divider,
  VStack,
  Text
} from '@chakra-ui/react';

import { ProductMobile } from './product/product-mobile';
import { DevelopersMobile } from './developers/developers-mobile';
import { CompanyMobile } from './company/company-mobile';
import { CustomersMobile } from './customers/customers-mobile';
import { URLS } from '@lib';
import { BookOpenIcon, CodeIcon } from '@heroicons/react/outline';
import { NavItemWithIcon } from './nav-item-with-icon';
import { NavItemWithIconMobile } from './nav-item-mobile';
import { AuthUsers } from './product/auth-users';
import { Orgs } from './product/orgs';
import { PrebuiltComponents } from './product/prebuilt-components';
import { CompanyIcon } from '@components/common';

const { home } = URLS;

export const HEADER_HEIGHT = '80px';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function MobileMenu({ isOpen, onClose }: Props): JSX.Element | null {
  const [selected, setSelected] = useState<string>('Home');

  const { pathname } = useRouter();

  const logo = '/images/clerk-logo.svg';
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!menuRef) return;

    if (isOpen) {
      disableBodyScroll(menuRef.current as HTMLDivElement);
    } else {
      enableBodyScroll(menuRef.current as HTMLDivElement);
    }
  }, [isOpen]);

  useEffect(() => {
    onClose();
  }, [pathname]);

  const OnCloseReset = () => {
    onClose();
    setSelected('Home');
  };

  const [shouldRender, setShouldRender] = React.useState(false);

  React.useEffect(() => {
    setShouldRender(true);
  }, []);

  // Workaround to prevent `Hydration failed because the initial UI does not match what was rendered on the server.`
  if (!shouldRender) return null;

  return (
    <AnimatePresence>
      <Box
        ref={menuRef}
        pos="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        overflow="auto"
        px={4}
        py={4}
        zIndex={isOpen ? 9999 : -9999}
        opacity={isOpen ? 1 : 0}
        transition="opacity .2s"
        bg="white"
      >
        {isOpen && (
          <motion.div
            transition={{ duration: 0.1 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box>
              {selected == 'Home' ? (
                <Flex
                  flexDir="column"
                  h="full"
                  w="full"
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Flex
                    flexDir="row"
                    justifyContent="space-between"
                    alignItems="center"
                    h="80px"
                    w="88vw"
                    mt={-3}
                  >
                    <Link href={home}>
                      <VisuallyHidden>Clerk</VisuallyHidden>
                      <Image
                        src={logo}
                        height="24"
                        width="76"
                        alt="Clerk logo"
                        priority
                      />
                    </Link>
                    <Box pos="relative" left={'-10px'}>
                      <CloseButton onClick={onClose} />
                    </Box>
                  </Flex>
                  <>
                    <VStack w="full" pos="relative" gap={6} px={4}>
                      <ProductMobile setSelected={setSelected} />

                      <Divider />
                      <Stack gap={6} w="90vw" pos="relative">
                        <Box>
                          <NavItemWithIcon
                            label="Documentation"
                            icon={BookOpenIcon}
                            iconBoxBg="linear-gradient(128.61deg, #6C47FF 0%, #17CCFC 152.52%)"
                            iconColor="gray.100"
                            noHover
                            href={'/docs'}
                          />
                        </Box>
                      </Stack>
                      <Stack gap={6} w="90vw" pos="relative">
                        <Box onClick={() => setSelected('Developers')}>
                          <NavItemWithIcon
                            label="Developers"
                            icon={() => (
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.75 12.75L16.5 9L12.75 5.25M5.25 5.25L1.5 9L5.25 12.75M10.5 2.25L7.5 15.75"
                                  stroke="url(#paint0_linear_1896_29589)"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <defs>
                                  <linearGradient
                                    id="paint0_linear_1896_29589"
                                    x1="2"
                                    y1="2"
                                    x2="20"
                                    y2="20"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#6C47FF" />
                                    <stop offset="1" stop-color="#17CCFC" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            )}
                            iconBoxBg={'gray.25'}
                            iconColor={'none'}
                            labelColor={'gray.700'}
                            iconSize={'18px'}
                          />
                        </Box>
                      </Stack>
                      <CustomersMobile />
                      <Stack gap={6} w="90vw" pos="relative" pb={4}>
                        <NavItemWithIcon
                          label="Blog"
                          icon={() => (
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.5 1.70215V4.80005C10.5 5.22009 10.5 5.43011 10.5817 5.59055C10.6537 5.73167 10.7684 5.8464 10.9095 5.91831C11.0699 6.00005 11.28 6.00005 11.7 6.00005H14.7979M10.5 12.75H6M12 9.75H6M15 7.49117V12.9C15 14.1601 15 14.7902 14.7548 15.2715C14.539 15.6948 14.1948 16.039 13.7715 16.2548C13.2902 16.5 12.6601 16.5 11.4 16.5H6.6C5.33988 16.5 4.70982 16.5 4.22852 16.2548C3.80516 16.039 3.46095 15.6948 3.24524 15.2715C3 14.7902 3 14.1601 3 12.9V5.1C3 3.83988 3 3.20982 3.24524 2.72852C3.46095 2.30516 3.80516 1.96095 4.22852 1.74524C4.70982 1.5 5.33988 1.5 6.6 1.5H9.00883C9.55916 1.5 9.83432 1.5 10.0933 1.56217C10.3229 1.61729 10.5423 1.7082 10.7436 1.83156C10.9707 1.9707 11.1653 2.16527 11.5544 2.55442L13.9456 4.94558C14.3347 5.33473 14.5293 5.5293 14.6684 5.75636C14.7918 5.95767 14.8827 6.17715 14.9378 6.40673C15 6.66568 15 6.94084 15 7.49117Z"
                                stroke="url(#paint0_linear_634_27119)"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_634_27119"
                                  x1="3"
                                  y1="2"
                                  x2="15"
                                  y2="17"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#6C47FF" />
                                  <stop offset="1" stopColor="#17CCFC" />
                                </linearGradient>
                              </defs>
                            </svg>
                          )}
                          href={'/blog'}
                          iconColor="gray.100"
                        />

                        <Box onClick={() => setSelected('Company')}>
                          <NavItemWithIcon
                            label={'Company'}
                            icon={CompanyIcon}
                            iconBoxBg={'gray.25'}
                            iconColor={'none'}
                            labelColor={'gray.700'}
                            iconSize={'18px'}
                          />
                        </Box>
                        <NavItemWithIcon
                          label="Start Building"
                          icon={() => (
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.2501 11.25V6.75004M11.2501 6.75004H6.75014M11.2501 6.75004L6.75014 11.25M5.85 15.75H12.15C13.4101 15.75 14.0402 15.75 14.5215 15.5048C14.9448 15.289 15.289 14.9448 15.5048 14.5215C15.75 14.0402 15.75 13.4101 15.75 12.15V5.85C15.75 4.58988 15.75 3.95982 15.5048 3.47852C15.289 3.05516 14.9448 2.71095 14.5215 2.49524C14.0402 2.25 13.4101 2.25 12.15 2.25H5.85C4.58988 2.25 3.95982 2.25 3.47852 2.49524C3.05516 2.71095 2.71095 3.05516 2.49524 3.47852C2.25 3.95982 2.25 4.58988 2.25 5.85V12.15C2.25 13.4101 2.25 14.0402 2.49524 14.5215C2.71095 14.9448 3.05516 15.289 3.47852 15.5048C3.95982 15.75 4.58988 15.75 5.85 15.75Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                          iconBoxBg="linear-gradient(128.61deg, #6C47FF 0%, #17CCFC 152.52%)"
                          href={
                            process.env.NEXT_PUBLIC_DASHBOARD_URL + '/sign-up'
                          }
                          iconColor="gray.100"
                          noHover
                        />
                      </Stack>
                    </VStack>
                  </>
                </Flex>
              ) : (
                <Flex flexDir="column" h="full">
                  <HStack
                    bg="white"
                    justifyContent="space-between"
                    alignItems={'center'}
                    pt="8px"
                    pb="24px"
                    px="24px"
                  >
                    <Box onClick={() => setSelected('Home')} cursor={'pointer'}>
                      <HStack>
                        <Text>Back</Text>
                      </HStack>
                    </Box>

                    <CloseButton onClick={OnCloseReset} />
                  </HStack>
                  <>
                    <VStack gap={6} pb={4} px={4}>
                      {selected == 'Developers' ? (
                        <DevelopersMobile />
                      ) : selected == 'Company' ? (
                        <CompanyMobile />
                      ) : selected == 'Authentication & Users' ? (
                        <AuthUsers />
                      ) : selected == 'Organizations (for B2B SaaS)' ? (
                        <Orgs />
                      ) : selected == 'Pre-built Components' ? (
                        <PrebuiltComponents />
                      ) : (
                        <></>
                      )}
                    </VStack>
                  </>
                </Flex>
              )}
            </Box>
          </motion.div>
        )}
      </Box>
    </AnimatePresence>
  );
}
