import * as React from 'react';

export function UserButtonIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.987 14.579A3 3 0 016.75 12.75h4.5a3 3 0 012.763 1.829M12 7.125a3 3 0 11-6 0 3 3 0 016 0zM16.5 9a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
        stroke="url(#prefix__paint0_linear_729_27333)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27333"
          x1={2}
          y1={1}
          x2={17}
          y2={17.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
