import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleFunction = () => {
  return {
    fontFamily: 'body',
    fontFeatureSettings:
      "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv06' on, 'cv07' on, 'cv08' on, 'ss01' on, 'ss14' on, 'ss02' on, 'ss04' on"
  };
};

export const Text = {
  baseStyle
};
