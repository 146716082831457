import Link from 'next/link';
import { Box, Flex, HStack, Stack, Text, Icon } from '@chakra-ui/react';
import { NavItemTextOnly } from '../nav-item-text-only';
import { useEffect, useState } from 'react';
import { CompanyMobile } from '../company/company-mobile';
import ArrowRightUpLineIcon from 'remixicon-react/ArrowRightUpLineIcon';

const company = [
  {
    label: 'About Us',
    href: '/company',
    icon: () => (
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9475 2.4755L10.1471 4.27585C10.0906 4.33236 10.0166 4.36824 9.93724 4.37773C9.85784 4.38723 9.77755 4.36978 9.70924 4.3282C9.00103 3.90097 8.18429 3.6879 7.35763 3.71471C6.53096 3.74153 5.72974 4.00709 5.05071 4.47932C4.63309 4.77001 4.27048 5.13263 3.97978 5.55025C3.50811 6.22969 3.2428 7.03095 3.21578 7.85763C3.18876 8.6843 3.40117 9.50118 3.82747 10.21C3.86875 10.2782 3.88602 10.3582 3.87654 10.4373C3.86705 10.5165 3.83134 10.5902 3.77511 10.6467L1.97476 12.447C1.93851 12.4835 1.8948 12.5117 1.84662 12.5298C1.79844 12.5478 1.74694 12.5552 1.69564 12.5515C1.64433 12.5477 1.59444 12.533 1.54937 12.5082C1.50431 12.4834 1.46514 12.4491 1.43454 12.4078C0.46044 11.0688 -0.0429742 9.44508 0.00287631 7.78994C0.0487269 6.1348 0.641263 4.54141 1.688 3.25846C2.00755 2.86593 2.36639 2.50709 2.75892 2.18754C4.04177 1.14109 5.63493 0.548726 7.28981 0.502877C8.9447 0.457028 10.5682 0.96027 11.907 1.93408C11.9487 1.96458 11.9832 2.00374 12.0082 2.04886C12.0333 2.09399 12.0482 2.144 12.0521 2.19546C12.0559 2.24692 12.0485 2.29861 12.0305 2.34696C12.0124 2.3953 11.9841 2.43915 11.9475 2.4755Z"
          fill="url(#paint0_linear_1776_30485)"
        />
        <path
          d="M11.9474 13.5179L10.147 11.7176C10.0905 11.6611 10.0166 11.6252 9.93716 11.6157C9.85776 11.6062 9.77747 11.6236 9.70916 11.6652C9.0414 12.068 8.27635 12.2809 7.49651 12.2809C6.71666 12.2809 5.95161 12.068 5.28385 11.6652C5.21554 11.6236 5.13525 11.6062 5.05585 11.6157C4.97645 11.6252 4.90254 11.6611 4.84596 11.7176L3.04561 13.5179C3.00785 13.5542 2.97855 13.5984 2.95977 13.6473C2.94099 13.6961 2.9332 13.7486 2.93695 13.8008C2.9407 13.853 2.95589 13.9038 2.98146 13.9495C3.00702 13.9952 3.04233 14.0347 3.08488 14.0653C4.36641 14.9977 5.91048 15.5 7.49532 15.5C9.08016 15.5 10.6242 14.9977 11.9058 14.0653C11.9485 14.0349 11.984 13.9956 12.0097 13.95C12.0355 13.9043 12.051 13.8536 12.0549 13.8014C12.0589 13.7491 12.0513 13.6966 12.0327 13.6477C12.0142 13.5987 11.985 13.5544 11.9474 13.5179V13.5179Z"
          fill="#1F0256"
        />
        <path
          d="M7.49732 10.1386C8.68024 10.1386 9.63918 9.17963 9.63918 7.99671C9.63918 6.8138 8.68024 5.85486 7.49732 5.85486C6.31441 5.85486 5.35547 6.8138 5.35547 7.99671C5.35547 9.17963 6.31441 10.1386 7.49732 10.1386Z"
          fill="#1F0256"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1776_30485"
            x1="10.2554"
            y1="-0.599257"
            x2="-4.92796"
            y2="14.5853"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#17CCFC" />
            <stop offset="0.5" stop-color="#5D31FF" />
            <stop offset="1" stop-color="#F35AFF" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'Blog',
    href: '/blog',
    icon: () => (
      <svg
        width="11"
        height="14"
        viewBox="0 0 11 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 11.375V9.5H2.375C1.33947 9.5 0.5 10.3395 0.5 11.375M3.5 13.25H8.5C9.20007 13.25 9.5501 13.25 9.81749 13.1138C10.0527 12.9939 10.2439 12.8027 10.3638 12.5675C10.5 12.3001 10.5 11.9501 10.5 11.25V2.75C10.5 2.04993 10.5 1.6999 10.3638 1.43251C10.2439 1.19731 10.0527 1.00608 9.81749 0.886242C9.5501 0.75 9.20007 0.75 8.5 0.75H3.5C2.4499 0.75 1.92485 0.75 1.52377 0.954363C1.17096 1.13413 0.884125 1.42096 0.704363 1.77377C0.5 2.17485 0.5 2.6999 0.5 3.75V10.25C0.5 11.3001 0.5 11.8251 0.704363 12.2262C0.884125 12.579 1.17096 12.8659 1.52377 13.0456C1.92485 13.25 2.4499 13.25 3.5 13.25Z"
          stroke="url(#paint0_linear_1778_10522)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1778_10522"
            x1="1"
            y1="0.5"
            x2="12"
            y2="13.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'Open Source',
    href: 'https://github.com/clerkinc',
    icon: () => (
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.0625 8.875L10.9375 7L9.0625 5.125M5.9375 5.125L4.0625 7L5.9375 8.875M13.75 7C13.75 10.4518 10.9518 13.25 7.5 13.25C4.04822 13.25 1.25 10.4518 1.25 7C1.25 3.54822 4.04822 0.75 7.5 0.75C10.9518 0.75 13.75 3.54822 13.75 7Z"
          stroke="url(#paint0_linear_1778_5568)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1778_5568"
            x1="3.5"
            y1="0.5"
            x2="14"
            y2="13.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'Security at Clerk',
    href: '/docs/security/introduction',
    icon: () => (
      <svg
        width="11"
        height="14"
        viewBox="0 0 11 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.06376 13.0093C5.20213 13.09 5.27131 13.1304 5.36895 13.1513C5.44472 13.1676 5.55528 13.1676 5.63105 13.1513C5.72869 13.1304 5.79787 13.09 5.93624 13.0093C7.15377 12.299 10.5 10.0678 10.5 6.99998V4.62498C10.5 3.9538 10.5 3.61821 10.3965 3.37998C10.2914 3.13786 10.1866 3.00905 9.97097 2.8568C9.7588 2.707 9.34289 2.6205 8.51106 2.44749C7.59425 2.25682 6.89017 1.9125 6.24649 1.41456C5.93782 1.17577 5.78348 1.05638 5.66271 1.02382C5.53527 0.989457 5.46473 0.989457 5.33729 1.02382C5.21652 1.05638 5.06218 1.17577 4.75351 1.41456C4.10983 1.9125 3.40575 2.25682 2.48894 2.44749C1.65711 2.6205 1.24119 2.707 1.02903 2.8568C0.813388 3.00905 0.708616 3.13786 0.603461 3.37998C0.5 3.61821 0.5 3.9538 0.5 4.62498V6.99998C0.5 10.0678 3.84623 12.299 5.06376 13.0093Z"
          stroke="url(#paint0_linear_1778_117)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1778_117"
            x1="1"
            y1="0.5"
            x2="11"
            y2="15"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'Careers (we’re hiring!)',
    href: 'https://apply.workable.com/clerk-dev/',
    icon: () => (
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99997 4.5V2.625L11.875 0.75L12.5 2L13.75 2.625L11.875 4.5H9.99997ZM9.99997 4.5L7.49998 6.99997M13.75 7C13.75 10.4518 10.9518 13.25 7.5 13.25C4.04822 13.25 1.25 10.4518 1.25 7C1.25 3.54822 4.04822 0.75 7.5 0.75M10.625 7C10.625 8.72589 9.22589 10.125 7.5 10.125C5.77411 10.125 4.375 8.72589 4.375 7C4.375 5.27411 5.77411 3.875 7.5 3.875"
          stroke="url(#paint0_linear_1778_1475)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1778_1475"
            x1="2.5"
            y1="3.44813e-07"
            x2="14"
            y2="12.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  }
];

interface Props {
  mobileView?: boolean;
}

interface Blog {
  title?: string;
  slug?: string;
  coverImage?: string;
}

const Divider = () => <Box bg="gray.100" w="1px" />;

export function Company({ mobileView }: Props): JSX.Element {
  const [blogs, setBlogs] = useState<Blog[]>([]);

  function formatTitle(title: string) {
    if (title.length > 140) {
      return title.substring(0, 140) + '...';
    }
    return title;
  }

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const result: Blog[] = [
          {
            title: 'How We Roll – Chapter 4: Email Verification',
            slug: '/blog/how-we-roll-email-verification',
            coverImage: '/images/how we roll 2.webp'
          },
          {
            title: 'Changelog June 9, 2023',
            slug: '/blog/changelog-2023-06-09',
            coverImage:
              'https://clerk.com/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fe1ql88v4%2Fproduction%2F522c12f1ef2660f20e3e043a9675f0423e9436f6-2400x1260.png%3Ffit%3Dmax%26auto%3Dformat&w=3840&q=75'
          }
        ];
        setBlogs(result);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  if (!mobileView) {
    return (
      <Flex gap={4} px="24px" pt="24px" pb="20px" bg="white" rounded="xl">
        <Box w="220px">
          <Text
            fontWeight={700}
            px={4}
            fontSize={'14px'}
            fontFamily={'heading'}
            pb={'12px'}
          >
            Company
          </Text>

          <Stack spacing={0} pt={2} px="12px">
            {company.map(({ label, href, icon }) => (
              <NavItemTextOnly
                key={label}
                label={label}
                href={href}
                icon={icon}
                py="6px"
                px="6px"
                hover
              />
            ))}
          </Stack>
        </Box>

        <Divider />

        <Box>
          <Text
            fontWeight={700}
            px={3}
            fontSize={'14px'}
            fontFamily={'heading'}
          >
            From the Blog
          </Text>
          <HStack pt="12px" gap={4} zIndex={100} w="500px">
            <Link href={'/blog/how-we-roll-customization'} passHref>
              <Box
                rounded="xl"
                height={262}
                width={221}
                bg="primary.500"
                border=" 0.5px solid #E4E9F2"
                borderRadius=" 8px"
                _hover={{
                  transform: 'scale(1.01)',
                  bg: 'linear-gradient(132.38deg, #17CCFC 0%, #6C47FF 83.48%);',
                  boxShadow: 'inset 4px 4px 4px rgba(0, 0, 0, 0.25)'
                }}
              >
                <Flex w="full" justifyContent={'flex-end'} p={4}>
                  <Icon as={ArrowRightUpLineIcon} color="white" />
                </Flex>
                <Flex
                  flexDir="column"
                  justifyContent="center"
                  alignItems="center"
                  h="60%"
                  mx="24px"
                  fontFamily="body"
                  fontStyle="normal"
                  fontWeight={700}
                  fontSize="14px"
                  lineHeight="20px"
                  letterSpacing="-0.02em"
                  color="#FFFFFF"
                >
                  <Text textAlign="left">
                    {formatTitle(`How We Roll – Chapter 5: Customization`)}
                  </Text>
                </Flex>
              </Box>
            </Link>
            <Link href={'/blog/changelog-2023-06-09'} passHref>
              <Box
                rounded="xl"
                height={262}
                width={221}
                bg="primary.500"
                border=" 0.5px solid #E4E9F2;"
                border-radius=" 8px;"
                _hover={{
                  transform: 'scale(1.01)',
                  bg: 'linear-gradient(321.68deg, #17CCFC -7.62%, #6C47FF 79.15%)',
                  boxShadow: 'inset 4px 4px 4px rgba(0, 0, 0, 0.25)'
                }}
              >
                <Flex w="full" justifyContent={'flex-end'} p={4}>
                  <Icon as={ArrowRightUpLineIcon} color="white" />
                </Flex>
                <Flex
                  flexDir="column"
                  justifyContent="center"
                  alignItems="center"
                  h="60%"
                  fontFamily="body"
                  fontStyle="normal"
                  fontWeight={700}
                  fontSize="14px"
                  lineHeight="20px"
                  letterSpacing="-0.02em"
                  color="#FFFFFF"
                >
                  <Text textAlign="left">
                    {formatTitle(`Changelog: June 9, 2023`)}
                  </Text>
                </Flex>
              </Box>
            </Link>
          </HStack>
        </Box>
      </Flex>
    );
  } else {
    return <CompanyMobile />;
  }
}
