export function CompanyIcon(props) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.3071 2.531L13.0005 4.8376C12.928 4.91001 12.8333 4.95597 12.7316 4.96814C12.6298 4.9803 12.527 4.95795 12.4395 4.90468C11.5321 4.35731 10.4857 4.08432 9.42657 4.11868C8.36745 4.15304 7.34093 4.49327 6.47095 5.09829C5.9359 5.47073 5.47132 5.93531 5.09889 6.47036C4.49458 7.34087 4.15466 8.36744 4.12004 9.42657C4.08542 10.4857 4.35757 11.5323 4.90374 12.4404C4.95663 12.5277 4.97876 12.6303 4.96661 12.7317C4.95445 12.8331 4.9087 12.9275 4.83666 12.9999L2.53006 15.3065C2.48361 15.3532 2.42761 15.3894 2.36588 15.4125C2.30416 15.4356 2.23818 15.4451 2.17244 15.4403C2.10671 15.4355 2.04279 15.4166 1.98505 15.3848C1.92732 15.353 1.87713 15.3092 1.83793 15.2562C0.589915 13.5408 -0.0550585 11.4604 0.00368512 9.33985C0.0624287 7.21929 0.821584 5.17784 2.16265 3.53413C2.57207 3.03122 3.03181 2.57148 3.53472 2.16206C5.1783 0.821361 7.21946 0.0624282 9.33969 0.0036861C11.4599 -0.055056 13.54 0.589697 15.2552 1.83734C15.3086 1.87641 15.3528 1.92658 15.3849 1.9844C15.417 2.04221 15.4361 2.10629 15.4411 2.17222C15.446 2.23815 15.4366 2.30437 15.4134 2.36631C15.3903 2.42825 15.354 2.48444 15.3071 2.531Z"
        fill="url(#paint0_linear_634_27126)"
      />
      <path
        d="M15.3071 2.531L13.0005 4.8376C12.928 4.91001 12.8333 4.95597 12.7316 4.96814C12.6298 4.9803 12.527 4.95795 12.4395 4.90468C11.5321 4.35731 10.4857 4.08432 9.42657 4.11868C8.36745 4.15304 7.34093 4.49327 6.47095 5.09829C5.9359 5.47073 5.47132 5.93531 5.09888 6.47036C4.49458 7.34087 4.15466 8.36744 4.12004 9.42657C4.08542 10.4857 4.35757 11.5323 4.90374 12.4404C4.95663 12.5277 4.97876 12.6303 4.96661 12.7317C4.95445 12.8331 4.9087 12.9275 4.83666 12.9999L2.53006 15.3065C2.48361 15.3533 2.42761 15.3894 2.36588 15.4125C2.30416 15.4356 2.23818 15.4451 2.17244 15.4403C2.10671 15.4355 2.04279 15.4166 1.98505 15.3848C1.92732 15.353 1.87713 15.3092 1.83793 15.2562C0.589915 13.5408 -0.0550585 11.4604 0.00368512 9.33985C0.0624287 7.21929 0.821584 5.17784 2.16265 3.53413C2.57207 3.03122 3.03181 2.57148 3.53472 2.16207C5.1783 0.821361 7.21946 0.0624282 9.33969 0.0036861C11.4599 -0.055056 13.54 0.589697 15.2552 1.83734C15.3086 1.87641 15.3528 1.92658 15.3849 1.9844C15.417 2.04221 15.4361 2.10629 15.4411 2.17222C15.446 2.23815 15.4366 2.30437 15.4134 2.36631C15.3903 2.42825 15.354 2.48444 15.3071 2.531Z"
        fill="url(#paint1_linear_634_27126)"
      />
      <path
        d="M15.307 16.6787L13.0004 14.3721C12.928 14.2997 12.8333 14.2537 12.7315 14.2415C12.6298 14.2294 12.5269 14.2517 12.4394 14.305C11.5839 14.8211 10.6037 15.0938 9.60458 15.0938C8.60544 15.0938 7.62526 14.8211 6.76973 14.305C6.68221 14.2517 6.57935 14.2294 6.47762 14.2415C6.37589 14.2537 6.28119 14.2997 6.2087 14.3721L3.9021 16.6787C3.85373 16.7252 3.81619 16.7817 3.79213 16.8444C3.76807 16.907 3.75809 16.9742 3.76289 17.0411C3.76769 17.108 3.78716 17.1731 3.81991 17.2316C3.85267 17.2902 3.8979 17.3408 3.95241 17.3799C5.59431 18.5745 7.57256 19.218 9.60305 19.218C11.6335 19.218 13.6118 18.5745 15.2537 17.3799C15.3084 17.3411 15.3539 17.2906 15.3869 17.2322C15.42 17.1737 15.4397 17.1087 15.4448 17.0418C15.4499 16.9749 15.4402 16.9076 15.4164 16.8449C15.3926 16.7821 15.3553 16.7254 15.307 16.6787V16.6787Z"
        fill="#1F0256"
      />
      <path
        d="M9.60547 12.3486C11.121 12.3486 12.3496 11.12 12.3496 9.60449C12.3496 8.08894 11.121 6.86035 9.60547 6.86035C8.08992 6.86035 6.86133 8.08894 6.86133 9.60449C6.86133 11.12 8.08992 12.3486 9.60547 12.3486Z"
        fill="#1F0256"
      />
      <defs>
        <linearGradient
          id="paint0_linear_634_27126"
          x1="13.1392"
          y1="-1.40836"
          x2="-6.31368"
          y2="18.0461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17CCFC" />
          <stop offset="0.5" stopColor="#5D31FF" />
          <stop offset="1" stopColor="#F35AFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_634_27126"
          x1="13.1392"
          y1="-1.40836"
          x2="-6.31368"
          y2="18.0461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17CCFC" />
          <stop offset="0.5" stopColor="#5D31FF" />
          <stop offset="1" stopColor="#F35AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
