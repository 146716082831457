export function NextJSIcon(props) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_1769_30367)">
        <path
          d="M7.5 0.5C3.36328 0.5 0 3.86328 0 8C0 12.1367 3.36328 15.5 7.5 15.5C8.8125 15.5 10.043 15.1602 11.1094 14.5742L5.67188 6.98047V11.2695H4.875V5.39844H5.67188L11.5898 14.2812C13.6406 12.9453 15 10.6367 15 8C15 3.86328 11.6367 0.5 7.5 0.5ZM10.0898 10.4141L9.21094 9.08984V5.39844H10.0898V10.4141Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1769_30367">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
