import React from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { CodeSnippet } from './code-snippet';
import { containerGradient } from '@theme/foundations/colors';

function Dot(): JSX.Element {
  return <Box boxSize={2} bg="white" opacity={0.2} rounded="full" />;
}

type Props = {
  snippet: string | React.ReactNode;
  language?: string;
  tabs?: React.ReactNode;
};

export function CodeWindow({ snippet, language, tabs }: Props): JSX.Element {
  return (
    <Box
      border="white.10"
      rounded={{ md: 'xl' }}
      mb={8}
      boxShadow="containerShadow"
      {...containerGradient}
    >
      <Stack justify="center" borderBottom="white.10" px={4} spacing={0}>
        <Flex gap={2} py={3}>
          <Dot />
          <Dot />
          <Dot />
        </Flex>

        {tabs && <Flex justify="space-between">{tabs}</Flex>}
      </Stack>

      <Box p={6}>
        {typeof snippet === 'string' ? (
          <CodeSnippet snippet={snippet} language="jsx" hasCopy />
        ) : (
          snippet
        )}
      </Box>
    </Box>
  );
}
