import * as React from 'react';

export function ManageUsersIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.25 11.25H6C4.60218 11.25 3.90326 11.25 3.35195 11.4784C2.61687 11.7828 2.03284 12.3669 1.72836 13.1019C1.5 13.6533 1.5 14.3522 1.5 15.75M11.625 2.46807C12.7244 2.91311 13.5 3.99098 13.5 5.25M8.9999 16.125L10.5187 15.8213C10.6511 15.7948 10.7174 15.7815 10.7791 15.7573C10.8339 15.7358 10.886 15.7079 10.9343 15.6743C10.9887 15.6363 11.0364 15.5886 11.1319 15.4931L16.125 10.5C16.5393 10.0858 16.5393 9.4142 16.125 8.99998C15.7108 8.58577 15.0392 8.58578 14.625 9L9.63189 13.9931C9.53641 14.0886 9.48866 14.1363 9.45073 14.1907C9.41705 14.239 9.38918 14.2911 9.36768 14.3459C9.34347 14.4076 9.33022 14.4738 9.30373 14.6062L8.9999 16.125ZM10.125 5.25C10.125 6.90685 8.78185 8.25 7.125 8.25C5.46815 8.25 4.125 6.90685 4.125 5.25C4.125 3.59315 5.46815 2.25 7.125 2.25C8.78185 2.25 10.125 3.59315 10.125 5.25Z"
        stroke="url(#paint0_linear_1837_29666)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1837_29666"
          x1="2"
          y1="2"
          x2="16"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
