export function RolesPermissionsIcon(props) {
  return (
    <svg
      width={18}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 14.75v-1.5a3.001 3.001 0 00-2.25-2.905m-2.625-8.877a3.001 3.001 0 010 5.564m1.125 7.718c0-1.398 0-2.097-.228-2.648a3 3 0 00-1.624-1.624c-.551-.228-1.25-.228-2.648-.228H6c-1.398 0-2.097 0-2.648.228a3 3 0 00-1.624 1.624c-.228.551-.228 1.25-.228 2.648m8.625-10.5a3 3 0 11-6 0 3 3 0 016 0z"
        stroke="url(#prefix__paint0_linear_729_27343)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27343"
          x1={2}
          y1={-1.5}
          x2={17}
          y2={15}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
