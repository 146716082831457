import * as React from 'react';

export function SignUpIcon(props) {
  return (
    <svg
      width={18}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 10.625H5.625c-1.047 0-1.57 0-1.996.13a3 3 0 00-2 2c-.129.425-.129.948-.129 1.995m12.75 0v-4.5M12 12.5h4.5m-5.625-7.875a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0z"
        stroke="url(#prefix__paint0_linear_729_27319)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27319"
          x1={3.5}
          y1={1}
          x2={17}
          y2={15}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
