import React from 'react';
import { useSession, useUser } from '@clerk/nextjs';
import {
  LiveChatLoaderProvider,
  Intercom,
  useChat
} from 'react-live-chat-loader';
import { analytics } from 'pages';
import { rudderTrack } from '@hooks/rudderTrack';
import { useRouter } from 'next/router';

export const LoadChatButton = () => {
  const router = useRouter();
  const [state, loadChat] = useChat();
  const { user } = useUser();

  return (
    <button onClick={() => loadChat({ open: true })}>
      <div
        onClick={() => {
          analytics.track(
            `Marketing_${
              router.pathname == '/' ? 'Home' : router.pathname
            } | Intercom Button Clicked`,
            {
              surface: `Marketing`,
              url: router.pathname,
              user: user ? user : null
            }
          );
          rudderTrack(
            `Marketing_${
              router.pathname == '/' ? 'Home' : router.pathname
            } | Intercom Button Clicked`,
            {
              surface: `Marketing`,
              url: router.pathname,
              user: user ? user : null
            }
          );
        }}
      >
        <Intercom />
      </div>
    </button>
  );
};

declare global {
  interface Window {
    intercomSettings: any;
  }
}

type ClerkIntercomWrapperProps = {
  children: JSX.Element;
};

export function IntercomProvider({ children }: ClerkIntercomWrapperProps) {
  const { user } = useUser();
  const { session } = useSession();
  const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID || '';

  React.useEffect(() => {
    const setUserData = async () => {
      const dapiUrl = process.env.NEXT_PUBLIC_DAPI_URL;

      try {
        const token = await session?.getToken();

        if (!token) {
          throw new Error('No token found');
        }

        const res = await fetch(`${dapiUrl}/v1/intercom/user_hash`, {
          headers: {
            Authorization: token
          }
        });

        if (!res.ok) {
          throw new Error('Failed to fetch user hash');
        }

        const { user_hash } = await res.json();

        if (user && user_hash) {
          const { id, fullName, primaryEmailAddress, phoneNumbers } = user;

          window.intercomSettings = {
            user_id: id,
            email: primaryEmailAddress?.emailAddress,
            name: fullName || '',
            phone: phoneNumbers[0]?.phoneNumber,
            user_hash
          };
        }
      } catch (error) {
        console.log('Error getting user hash', error);
      }
    };

    if (user && session) {
      setUserData();
    }
  }, [session, user]);

  return (
    <LiveChatLoaderProvider providerKey={intercomAppId} provider="intercom">
      {children}
      <LoadChatButton />
    </LiveChatLoaderProvider>
  );
}
