import React, { useState } from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Product } from '../header/product/product';
import { Developers } from '../header/developers/developers';
import { Company } from '../header/company/company';
import { Box, chakra } from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { motion, isValidMotionProp, useAnimation } from 'framer-motion';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children'
});

const RadixMenu = ({ textColor }) => {
  const [selected, setSelected] = useState('product');
  const [value, setValue] = React.useState('');
  const { asPath } = useRouter();
  const controls = useAnimation();

  const onEnter = async () => {
    await controls.start({
      opacity: [0, 1],
      x: [-200, 0],
      transition: {
        duration: 0.25,
        type: 'tween'
      }
    });
  };

  const handleOnClick = async component => {
    onEnter();
    await setSelected(component);
  };

  React.useEffect(() => {
    setValue('');
  }, [asPath]);

  return (
    <NavigationMenu.Root
      value={value}
      onValueChange={setValue}
      className="absolute z-[1] flex w-screen justify-center left-[0] top-5"
    >
      <NavigationMenu.List className="center shadow-blackA7 m-0 flex gap-4 list-none rounded-[6px] p-1 w-full relative ">
        <NavigationMenu.Item>
          <NavigationMenu.Trigger
            className={` group flex select-none items-center justify-between gap-[2px] rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]`}
          >
            <Box
              _hover={{ color: 'primary.500' }}
              onMouseEnter={() => {
                if (selected !== 'product') {
                  handleOnClick('product');
                }
              }}
              color={textColor}
            >
              {' '}
              Product
            </Box>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full sm:w-auto">
            <ChakraBox as={motion.div} animate={controls}>
              <Product />
            </ChakraBox>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Trigger
            className={` group flex select-none items-center justify-between gap-[2px] rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]`}
          >
            <Box
              _hover={{ color: 'primary.500' }}
              onMouseEnter={() => {
                if (selected !== 'developers') {
                  handleOnClick('developers');
                }
              }}
              color={textColor}
            >
              {' '}
              Developers
            </Box>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full sm:w-auto">
            <ChakraBox as={motion.div} animate={controls}>
              <Developers />
            </ChakraBox>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Trigger
            className={` group flex select-none items-center justify-between gap-[2px] rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]`}
          >
            <Box
              _hover={{ color: 'primary.500' }}
              onMouseEnter={() => {
                if (selected !== 'company') {
                  handleOnClick('company');
                }
              }}
              color={textColor}
            >
              {' '}
              Company
            </Box>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 sm:w-auto">
            <ChakraBox as={motion.div} animate={controls}>
              <Company />
            </ChakraBox>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link
            className={` block select-none rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none no-underline outline-none focus:shadow-[0_0_0_2px`}
            href="/startups"
          >
            <Box
              _hover={{ color: 'primary.500' }}
              whiteSpace={'nowrap'}
              color={textColor}
            >
              For Startups
            </Box>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link
            className={` block select-none rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none no-underline outline-none focus:shadow-[0_0_0_2px`}
            href="/customers"
          >
            <Box _hover={{ color: 'primary.500' }} color={textColor}>
              Customers
            </Box>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link
            className={` block select-none rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none no-underline outline-none focus:shadow-[0_0_0_2px`}
            href="/pricing"
          >
            <Box _hover={{ color: 'primary.500' }} color={textColor}>
              Pricing
            </Box>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Indicator className="data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut top-full z-[1] flex h-[10px] items-end justify-center overflow-hidden transition-[width,transform_250ms_ease]">
          <Box pos={'relative'} top={'1px'}>
            {selected == 'product' ? (
              <Image
                src="/images/header/menu-triangle-up.svg"
                width={19.13}
                height={29.23}
                alt=""
              />
            ) : (
              <Image
                src="/images/header/menu-triangle-up-light.svg"
                width={19.13}
                height={29.23}
                alt=""
              />
            )}
          </Box>
        </NavigationMenu.Indicator>
      </NavigationMenu.List>

      <div className="perspective-[2000px] absolute top-full left-0 flex w-full justify-center">
        <NavigationMenu.Viewport className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-screen origin-[top_center] overflow-hidden rounded-[12px] bg-white transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)] shadow-lg" />
      </div>
    </NavigationMenu.Root>
  );
};

export default RadixMenu;
