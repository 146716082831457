import {
  Box,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  chakra
} from '@chakra-ui/react';

import { NavItemWithIcon } from '../nav-item-with-icon';
import Image from 'next/image';
import { motion, isValidMotionProp, useAnimation } from 'framer-motion';
import { SVGProps, useEffect, useState } from 'react';
import CustomFlowsComponent from './custom-flows-button';
import { sections } from './content';

import Link from 'next/link';
import { NavItemWithIconMobile } from '../nav-item-mobile';
import { APIIcon } from '@components/common';
import { ChevronRightIcon } from '@heroicons/react/outline';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children'
});

type Feature = {
  label: string;
  description?: string;
  icon: any;
  href: string;
};

export type Section = {
  name: string;
  description: string;
  features: Feature[];
  icon: any;
};

export function Product(): JSX.Element | null {
  const [selected, setSelected] = useState<Section>(sections[0]);

  const SectionControls = useAnimation();
  const ContentControls = useAnimation();

  useEffect(() => {
    if (selected.name == 'Authentication & Users') {
      // Animation for other sections
      SectionControls.start({
        y: 0,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
      ContentControls.start({
        y: 0,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
    } else if (selected.name == 'Pre-built Components') {
      // Animation for 'Pre-built Compon' section
      SectionControls.start({
        y: 110,

        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
      ContentControls.start({
        y: -360,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
    } else {
      // Animation for other sections
      SectionControls.start({
        y: 225,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
      ContentControls.start({
        y: -770,
        transition: {
          duration: 0.25,
          type: 'tween',
          ease: 'easeInOut'
        }
      });
    }
  }, [selected]);

  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  if (!shouldRender) return null;

  return (
    <>
      <HStack
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        mr="16px"
        h="380px"
        w="fit-content"
        bg="white"
        zIndex={100}
        minW="808px"
        rounded="xl"
      >
        <Flex
          bg="#F7F9FC"
          minW="252px"
          w="272px"
          h="full"
          flexDir="column"
          justifyContent={'space-evenly'}
          px="12px"
          pos="relative"
        >
          {sections.map(section => {
            return (
              <Box
                rounded="xl"
                zIndex={1}
                ml="16px"
                cursor="pointer"
                onMouseEnter={() => {
                  setSelected(section);
                }}
                key={section.name}
              >
                <Text
                  fontFamily="body"
                  fontStyle="normal"
                  fontWeight={700}
                  fontSize={14}
                  lineHeight="24px"
                  letterSpacing="-0.02em"
                  color="#262F40"
                >
                  {section.name}
                </Text>
                <Text
                  fontFamily="body"
                  fontStyle="normal"
                  fontWeight={400}
                  fontSize={14}
                  lineHeight="18px"
                  letterSpacing="-0.02em"
                  color="#626B7F"
                  maxW="220px"
                >
                  {section.description}
                </Text>
              </Box>
            );
          })}
        </Flex>

        <VStack h="full" bg="white" px={'12px'} overflow="hidden">
          <ChakraBox
            as={motion.div}
            animate={ContentControls}
            key={sections[0].name}
          >
            <Flex
              display="flex"
              alignItems={'flex-start'}
              pt="20px"
              flex={1}
              h="300px"
            >
              <Flex
                pos="relative"
                flexDir={'column'}
                justifyContent={'flex-start'}
                gap={'12px'}
              >
                {sections[0].features.slice(0, 5).map((feature, index) => (
                  <HStack key={index}>
                    <NavItemWithIcon
                      label={feature.label}
                      href={feature.href}
                      icon={feature.icon}
                      iconBoxBg="gray.25"
                      iconColor="linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)"
                      iconSize="18px"
                    />
                  </HStack>
                ))}
              </Flex>

              <Flex
                pos="relative"
                flexDir={'column'}
                justifyContent={'flex-start'}
                gap={'12px'}
              >
                {sections[0].features
                  .slice(5, sections[0].features.length)
                  .map((feature, index) => (
                    <Box key={index}>
                      <NavItemWithIcon
                        label={feature.label}
                        href={feature.href}
                        icon={feature.icon}
                        iconBoxBg="gray.25"
                        iconColor="linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)"
                        iconSize="18px"
                      />
                    </Box>
                  ))}
              </Flex>
            </Flex>

            {/* Pre-built components */}
            <Box
              display="flex"
              flexDir="column"
              justifyContent={'space-between'}
              pt="80px"
            >
              <Flex display="flex" alignItems={'flex-start'} flex={1} mb="6px">
                <Flex
                  pos="relative"
                  flexDir={'column'}
                  justifyContent={'flex-start'}
                  gap="8px"
                >
                  {sections[1].features.slice(0, 4).map((feature, index) => (
                    <HStack key={index}>
                      <NavItemWithIcon
                        label={feature.label}
                        href={feature.href}
                        icon={feature.icon}
                        iconBoxBg="gray.25"
                        iconColor="linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)"
                        iconSize="18px"
                      />
                    </HStack>
                  ))}
                </Flex>

                <Flex
                  pos="relative"
                  flexDir={'column'}
                  justifyContent={'flex-start'}
                  gap="8px"
                >
                  {sections[1].features
                    .slice(4, sections[0].features.length)
                    .map((feature, index) => (
                      <Box key={index}>
                        <NavItemWithIcon
                          label={feature.label}
                          href={feature.href}
                          icon={feature.icon}
                          iconBoxBg="gray.25"
                          iconColor="linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)"
                          iconSize="18px"
                        />
                      </Box>
                    ))}
                </Flex>
              </Flex>

              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                w="full"
                pt="18px"
              >
                <CustomFlowsComponent />
              </Box>
            </Box>

            {/* Orgs */}
            <Box
              display="flex"
              flexDir="column"
              h="310px"
              justifyContent={'space-between'}
              pt="80px"
            >
              <Flex
                display="flex"
                alignItems={'flex-start'}
                flex={1}
                pb="148px"
              >
                <Flex
                  pos="relative"
                  flexDir={'column'}
                  justifyContent={'flex-start'}
                  gap="8px"
                >
                  {sections[2].features.slice(0, 2).map((feature, index) => (
                    <HStack key={index}>
                      <NavItemWithIcon
                        label={feature.label}
                        href={feature.href}
                        icon={feature.icon}
                        iconBoxBg="gray.25"
                        iconColor="linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)"
                        iconSize="18px"
                      />
                    </HStack>
                  ))}
                </Flex>

                <Flex
                  pos="relative"
                  flexDir={'column'}
                  justifyContent={'flex-start'}
                  gap="8px"
                >
                  {sections[2].features
                    .slice(2, sections[0].features.length)
                    .map((feature, index) => (
                      <Box key={index}>
                        <NavItemWithIcon
                          label={feature.label}
                          href={feature.href}
                          icon={feature.icon}
                          iconBoxBg="gray.25"
                          iconColor="linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)"
                          iconSize="18px"
                        />
                      </Box>
                    ))}
                </Flex>
              </Flex>

              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                w="full"
              >
                <CustomFlowsComponent />
              </Box>
            </Box>
          </ChakraBox>
        </VStack>
      </HStack>

      <ChakraBox
        as={motion.div}
        animate={SectionControls}
        pos="relative"
        top={-454}
        left={-5}
      >
        <Box
          w="258px"
          h="100px"
          borderRadius={'12px'}
          bg="white"
          left="30px"
          top="106px"
          pos="absolute"
        ></Box>
        <Box pos="absolute" top={'142px'} left={'286px'} zIndex={0}>
          <Image
            src="/images/header/menu-triangle-dark-right.svg"
            width={19.13}
            height={29.23}
            alt=""
          />
        </Box>
      </ChakraBox>
    </>
  );
}
