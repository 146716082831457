export function UserProfileIcon(props) {
  return (
    <svg
      width={18}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.75 10.625H5.625c-1.047 0-1.57 0-1.996.13a3 3 0 00-2 2c-.129.425-.129.948-.129 1.995m9.375-10.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM8.25 14.75l2.326-.665c.111-.031.167-.047.219-.071a.738.738 0 00.13-.077c.047-.034.088-.075.17-.157l4.842-4.842a1.326 1.326 0 10-1.874-1.876L9.22 11.905a1.562 1.562 0 00-.157.17.747.747 0 00-.077.13c-.024.052-.04.108-.071.219L8.25 14.75z"
        stroke="url(#prefix__paint0_linear_729_27335)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27335"
          x1={3.5}
          y1={-0.5}
          x2={17.5}
          y2={15}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
