import { ComponentIcon } from '@components/common';
import {
  AuthatEdgeIcon,
  CreateOrgIcon,
  EmailMagicLinksIcon,
  EmailSMSIcon,
  InvitationsIcon,
  ManageUsersIcon,
  MultifactorIcon,
  OrgProfileIcon,
  OrgSwitcherIcon,
  PasswordIcon,
  RolesPermissionsIcon,
  SAMLIcon,
  SecurityIcon,
  SessionManagementIcon,
  SignInIcon,
  SignUpIcon,
  SocialSSOIcon,
  UserButtonIcon,
  UserProfileIcon,
  Web3Icon
} from './icons';
import PreBuiltComponentsIcon from './icons/prebuilt-components';

export const sections = [
  // Authentication & Users
  {
    name: 'Authentication & Users',
    description: 'Sign up, sign in, and user profile management.',
    icon: () => (
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.75 8.62478L6.25 10.1248L9.625 6.74978M13 8.99978C13 12.6811 8.98453 15.3586 7.52349 16.2109C7.35745 16.3078 7.27443 16.3562 7.15726 16.3814C7.06633 16.4009 6.93367 16.4009 6.84274 16.3814C6.72557 16.3562 6.64255 16.3078 6.47651 16.2109C5.01547 15.3586 1 12.6811 1 8.99978V5.41298C1 4.81335 1 4.51353 1.09807 4.25581C1.18471 4.02814 1.32549 3.82499 1.50824 3.66393C1.71512 3.48161 1.99585 3.37634 2.5573 3.16579L6.57865 1.65779C6.73457 1.59932 6.81253 1.57008 6.89274 1.55849C6.96388 1.54821 7.03612 1.54821 7.10726 1.55849C7.18747 1.57008 7.26543 1.59932 7.42135 1.65779L11.4427 3.16579C12.0042 3.37634 12.2849 3.48161 12.4918 3.66393C12.6745 3.82499 12.8153 4.02814 12.9019 4.25581C13 4.51353 13 4.81335 13 5.41298V8.99978Z"
          stroke="url(#paint0_linear_659_5915)"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_659_5915"
            x1="1"
            y1="1.49979"
            x2="13"
            y2="16.4998"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    ),
    features: [
      {
        label: 'Passwords',

        icon: PasswordIcon,
        href: '/features/passwords'
      },
      {
        label: 'Email Magic Links',

        icon: EmailMagicLinksIcon,
        href: '/features/email-magic-links'
      },

      {
        label: 'Social SSO',

        icon: SocialSSOIcon,
        href: '/features/social-sso'
      },
      {
        label: 'Session Management',

        icon: SessionManagementIcon,
        href: '/features/session-management'
      },
      {
        label: 'Advanced Security',

        icon: SecurityIcon,
        href: '/features/security'
      },
      {
        label: 'Email & SMS Passwords (OTP)',

        icon: EmailSMSIcon,
        href: '/features/email-sms-passcodes-otp'
      },

      {
        label: 'Multifactor Authentication',

        icon: MultifactorIcon,

        href: '/features/multifactor-authentication'
      },
      {
        label: 'Authentication at the Edge',

        icon: AuthatEdgeIcon,
        href: 'https://edge.clerk.app/'
      },
      {
        label: 'Web3 Authentication',

        icon: Web3Icon,
        href: '/features/web3'
      },
      {
        label: 'Manage Users',

        icon: ManageUsersIcon,
        href: '/docs/users/overview'
      }
    ]
  },
  // Pre-built Components
  {
    name: 'Pre-built Components',
    description:
      'Prebuilt and customizable UI components to get you up and running in minutes.',
    icon: () => <ComponentIcon />,
    features: [
      {
        label: '<SignIn />',

        icon: SignInIcon,
        href: '/docs/authentication/sign-in'
      },
      {
        label: '<SignUp />',

        icon: SignUpIcon,
        href: '/docs/authentication/sign-up'
      },
      {
        label: '<UserButton />',

        icon: UserButtonIcon,
        href: '/docs/users/user-button'
      },
      {
        label: '<UserProfile />',

        icon: UserProfileIcon,
        href: '/docs/users/user-profile'
      },
      {
        label: '<CreateOrganization />',

        icon: CreateOrgIcon,
        href: '/docs/component-reference/createorganization'
      },
      {
        label: '<OrganizationProfile />',

        icon: OrgProfileIcon,
        href: '/docs/component-reference/organizationprofile'
      },
      {
        label: '<OrganizationSwitcher />',

        icon: OrgSwitcherIcon,
        href: '/docs/component-reference/organizationswitcher'
      }
    ]
  },
  // Organizations
  {
    name: 'Organizations (for B2B SaaS)',
    description:
      'Enrollment, onboarding, and roles for organization customers.',
    icon: () => (
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.625 7.25H3.45C3.02996 7.25 2.81994 7.25 2.65951 7.33175C2.51839 7.40365 2.40365 7.51839 2.33175 7.65951C2.25 7.81994 2.25 8.02996 2.25 8.45V14.75M12.375 7.25H14.55C14.97 7.25 15.1801 7.25 15.3405 7.33175C15.4816 7.40365 15.5963 7.51839 15.6683 7.65951C15.75 7.81994 15.75 8.02996 15.75 8.45V14.75M12.375 14.75V3.65C12.375 2.80992 12.375 2.38988 12.2115 2.06901C12.0677 1.78677 11.8382 1.5573 11.556 1.41349C11.2351 1.25 10.8151 1.25 9.975 1.25H8.025C7.18492 1.25 6.76488 1.25 6.44401 1.41349C6.16177 1.5573 5.9323 1.78677 5.78849 2.06901C5.625 2.38988 5.625 2.80992 5.625 3.65V14.75M16.5 14.75H1.5M8.25 4.25H9.75M8.25 7.25H9.75M8.25 10.25H9.75"
          stroke="url(#paint0_linear_659_3111)"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_659_3111"
            x1="1.5"
            y1="1.25"
            x2="18.75"
            y2="17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    ),
    features: [
      {
        label: 'Invitations',

        icon: InvitationsIcon,
        href: '/docs/authentication/invitations'
      },
      {
        label: 'SAML SSO',

        icon: SAMLIcon,
        href: '/docs/authentication/saml-at-clerk'
      },
      {
        label: 'Roles and permissions',

        icon: RolesPermissionsIcon,
        href: '/docs/organizations/organization-switching'
      },
      {
        label: 'Pre-built components',

        icon: PreBuiltComponentsIcon,
        href: '/components#b2b-components'
      }
    ]
  }
];

export const bottomButtons = [
  {
    label: 'Pre-built components',

    description: 'Lorem ipsum dolor sit amet'
  },
  {
    label: 'APIs and SDKs',

    description: 'Lorem ipsum dolor sit amet'
  }
];
