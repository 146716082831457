export function Web3Icon(props) {
  return (
    <svg
      width={16}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.375 5.458L8 8.999m0 0L1.625 5.458M8 8.999v7.126m6.75-4.082V5.956c0-.257 0-.386-.038-.5a.75.75 0 00-.16-.274c-.082-.088-.195-.15-.42-.275l-5.55-3.084c-.212-.118-.318-.177-.43-.2a.75.75 0 00-.303 0c-.113.023-.22.082-.432.2l-5.55 3.084c-.224.124-.337.187-.419.276a.75.75 0 00-.16.272c-.038.115-.038.244-.038.5v6.088c0 .257 0 .386.038.5a.75.75 0 00.16.273c.082.09.195.152.42.276l5.55 3.084c.212.118.318.177.43.2.1.02.203.02.303 0 .113-.023.22-.082.432-.2l5.55-3.084c.224-.124.337-.187.419-.276a.75.75 0 00.16-.273c.038-.114.038-.243.038-.5z"
        stroke="url(#prefix__paint0_linear_729_27329)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27329"
          x1={4}
          y1={2}
          x2={15}
          y2={15.999}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
