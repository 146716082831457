export function SessionManagementIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.75 8.62502L8.25 10.125L11.625 6.75003M15 9.00002C15 12.6814 10.9845 15.3588 9.52349 16.2112C9.35745 16.3081 9.27443 16.3565 9.15726 16.3816C9.06633 16.4011 8.93367 16.4011 8.84274 16.3816C8.72557 16.3565 8.64255 16.3081 8.47651 16.2112C7.01547 15.3588 3 12.6814 3 9.00002V5.41323C3 4.81359 3 4.51378 3.09807 4.25605C3.18471 4.02838 3.32549 3.82523 3.50824 3.66417C3.71512 3.48185 3.99585 3.37658 4.5573 3.16604L8.57865 1.65803C8.73457 1.59956 8.81253 1.57032 8.89274 1.55874C8.96388 1.54846 9.03612 1.54846 9.10726 1.55874C9.18747 1.57032 9.26543 1.59956 9.42135 1.65803L13.4427 3.16604C14.0042 3.37658 14.2849 3.48185 14.4918 3.66417C14.6745 3.82523 14.8153 4.02838 14.9019 4.25605C15 4.51378 15 4.81359 15 5.41323V9.00002Z"
        stroke="url(#paint0_linear_1837_29615)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1837_29615"
          x1="5"
          y1="2"
          x2="13.5"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
