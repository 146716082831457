import * as React from 'react';

function PreBuiltComponentsIcon(props) {
  return (
    <svg
      width={18}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 4.25l-.837-1.673c-.24-.482-.36-.723-.54-.899a1.5 1.5 0 00-.56-.346c-.239-.082-.508-.082-1.046-.082H3.9c-.84 0-1.26 0-1.581.163a1.5 1.5 0 00-.656.656c-.163.32-.163.74-.163 1.581v.6m0 0h11.4c1.26 0 1.89 0 2.371.245.424.216.768.56.984.984.245.48.245 1.11.245 2.371v3.3c0 1.26 0 1.89-.245 2.371-.216.424-.56.768-.983.984-.482.245-1.112.245-2.372.245H5.1c-1.26 0-1.89 0-2.371-.245a2.25 2.25 0 01-.984-.984c-.245-.48-.245-1.11-.245-2.371v-6.9zm9 7.125L12.375 9.5 10.5 7.625m-3 0L5.625 9.5 7.5 11.375"
        stroke="url(#prefix__paint0_linear_1878_16330)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_1878_16330"
          x1={1}
          y1={-0.5}
          x2={18.5}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PreBuiltComponentsIcon;
