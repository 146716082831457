import { Box, Flex, Icon, Link, Stack, Text } from '@chakra-ui/react';

import Image from 'next/image';
import { ArrowRightIcon } from '@heroicons/react/outline';
import {
  NextJSIcon,
  GatsbyIcon,
  ReactIcon,
  ExpoIcon,
  HasuraIcon,
  FirebaseIcon,
  SupabaseIcon,
  RedwoodIcon,
  FaunaIcon,
  RemixIcon,
  GrafbaseIcon
} from '../../../common/icons';
import { NavItemTextOnly } from '../nav-item-text-only';

const integrations = [
  {
    label: 'NextJS',
    href: '/docs/nextjs/overview',
    icon: () => <Icon as={NextJSIcon} />
  },
  {
    label: 'Remix',
    href: '/docs/quickstarts/get-started-with-remix',
    icon: () => <Icon as={RemixIcon} />
  },
  {
    label: 'React',
    href: '/docs/quickstarts/get-started-with-create-react-app',
    icon: () => <Icon as={ReactIcon} />
  },
  {
    label: 'RedwoodJS',
    href: '/docs/quickstarts/get-started-with-redwoodjs',
    icon: () => <Icon as={RedwoodIcon} />
  },
  {
    label: 'Gatsby',
    href: '/docs/quickstarts/get-started-with-gatsby',
    icon: () => <Icon as={GatsbyIcon} />
  },
  {
    label: 'React Native / Expo',
    href: '/docs/quickstarts/get-started-with-redwoodjs',
    icon: () => <Icon as={ExpoIcon} />
  }
];

const databases = [
  {
    label: 'Hasura',
    href: '/integrations/hasura',
    icon: () => <Icon as={HasuraIcon} />
  },
  {
    label: 'Supabase',
    href: '/integrations/supabase',
    icon: () => <Icon as={SupabaseIcon} />
  },
  {
    label: 'Fauna',
    href: '/integrations/fauna',
    icon: () => <Icon as={FaunaIcon} />
  },
  {
    label: 'Firebase',
    href: '/integrations/firebase',
    icon: () => <Icon as={FirebaseIcon} />
  },
  {
    label: 'Grafbase',
    href: '/integrations/grafbase',
    icon: () => <Icon as={GrafbaseIcon} />
  }
];

const learn = [
  {
    label: 'Tutorials',
    href: '/tutorials',
    icon: () => (
      <svg
        width="15"
        height="12"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.125 4.74998V8.50695C3.125 8.73129 3.125 8.84347 3.15916 8.9425C3.18937 9.03008 3.23867 9.10985 3.30349 9.17603C3.37679 9.25087 3.47713 9.30104 3.67779 9.40137L7.05279 11.0889C7.21676 11.1709 7.29875 11.2119 7.38475 11.228C7.46092 11.2423 7.53908 11.2423 7.61525 11.228C7.70125 11.2119 7.78324 11.1709 7.94721 11.0889L11.3222 9.40137C11.5229 9.30104 11.6232 9.25088 11.6965 9.17603C11.7613 9.10985 11.8106 9.03008 11.8408 8.9425C11.875 8.84347 11.875 8.73129 11.875 8.50695V4.74998M1.25 3.81248L7.27639 0.799283C7.35838 0.758289 7.39938 0.737791 7.44238 0.729724C7.48046 0.722579 7.51954 0.722579 7.55762 0.729724C7.60062 0.737791 7.64162 0.758289 7.72361 0.799283L13.75 3.81248L7.72361 6.82568C7.64162 6.86667 7.60062 6.88717 7.55762 6.89524C7.51954 6.90238 7.48046 6.90238 7.44238 6.89524C7.39938 6.88717 7.35838 6.86667 7.27639 6.82568L1.25 3.81248Z"
          stroke="url(#paint0_linear_1768_29514)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1768_29514"
            x1="3"
            y1="1"
            x2="14"
            y2="11"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'Public Roadmap',
    href: 'https://clerkdev.notion.site/019192f70e864d40a44870102cf2176f?v=c594d282a5f544b39aa0612842e9fd6b',
    icon: () => (
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.625 10.75L1.25 13.25V3.25L5.625 0.75M5.625 10.75L10 13.25M5.625 10.75V0.75M10 13.25L13.75 10.75V0.75L10 3.25M10 13.25V3.25M10 3.25L5.625 0.75"
          stroke="url(#paint0_linear_1768_29515)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1768_29515"
            x1="1"
            y1="1"
            x2="15.5"
            y2="13"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'Support',
    href: '/support',
    icon: () => (
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.49572 2.70988C6.24612 1.249 4.16234 0.856027 2.59668 2.19376C1.03103 3.53149 0.810605 5.76811 2.04012 7.35025C3.06239 8.66569 6.15611 11.4401 7.17006 12.338C7.2835 12.4385 7.34022 12.4887 7.40638 12.5085C7.46413 12.5257 7.52731 12.5257 7.58506 12.5085C7.65122 12.4887 7.70794 12.4385 7.82138 12.338C8.83533 11.4401 11.9291 8.66569 12.9513 7.35025C14.1808 5.76811 13.9873 3.51742 12.3948 2.19376C10.8022 0.870098 8.74532 1.249 7.49572 2.70988Z"
          stroke="url(#paint0_linear_1768_29517)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1768_29517"
            x1="1"
            y1="1.5"
            x2="15.5"
            y2="14"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'API & SDK Reference',
    href: '/docs/reference/overview',
    icon: () => (
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.75 6.375H3M4.25 8.875H3M8 3.875H3M10.5 6.0625V3.75C10.5 2.6999 10.5 2.17485 10.2956 1.77377C10.1159 1.42096 9.82904 1.13413 9.47623 0.954363C9.07515 0.75 8.5501 0.75 7.5 0.75H3.5C2.4499 0.75 1.92485 0.75 1.52377 0.954363C1.17096 1.13413 0.884125 1.42096 0.704363 1.77377C0.5 2.17485 0.5 2.6999 0.5 3.75V10.25C0.5 11.3001 0.5 11.8251 0.704363 12.2262C0.884125 12.579 1.17096 12.8659 1.52377 13.0456C1.92485 13.25 2.4499 13.25 3.5 13.25H5.1875M11.75 13.25L10.8125 12.3125M11.4375 10.75C11.4375 11.9581 10.4581 12.9375 9.25 12.9375C8.04188 12.9375 7.0625 11.9581 7.0625 10.75C7.0625 9.54188 8.04188 8.5625 9.25 8.5625C10.4581 8.5625 11.4375 9.54188 11.4375 10.75Z"
          stroke="url(#paint0_linear_1768_29518)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1768_29518"
            x1="1"
            y1="0.5"
            x2="14.5"
            y2="15.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'API Status',
    href: 'https://status.clerk.dev/',
    icon: () => (
      <svg
        width="15"
        height="10"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1517 2.34833C11.6161 3.8128 11.6161 6.18717 10.1517 7.65163M4.84835 7.65161C3.38388 6.18715 3.38388 3.81278 4.84835 2.34831M3.08058 9.4194C0.639806 6.97862 0.639806 3.02134 3.08058 0.580566M11.9194 0.580595C14.3602 3.02137 14.3602 6.97865 11.9194 9.41943M8.75 4.99998C8.75 5.69034 8.19036 6.24998 7.5 6.24998C6.80964 6.24998 6.25 5.69034 6.25 4.99998C6.25 4.30963 6.80964 3.74998 7.5 3.74998C8.19036 3.74998 8.75 4.30963 8.75 4.99998Z"
          stroke="url(#paint0_linear_1768_29519)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1768_29519"
            x1="1"
            y1="-1"
            x2="15"
            y2="10.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C47FF" />
            <stop offset="1" stop-color="#17CCFC" />
          </linearGradient>
        </defs>
      </svg>
    )
  },
  {
    label: 'Community',
    href: 'https://discord.com/invite/b5rXHjAg7A',
    icon: () => (
      <Image src="/images/discord.png" width={15} height={10} alt="" />
    )
  }
];

export function DevelopersMobile(): JSX.Element {
  return (
    <Flex gap={6} w="90vw" pos="relative" flexDir={'column'}>
      <Stack flex={1} spacing={7}>
        <Flex w="360px" flexDir={'column'} gap={6}>
          <Box flex={1}>
            <Text
              w="100%"
              color={'gray.700'}
              _groupHover={{ color: 'primary.500' }}
              fontFamily={'heading'}
              fontWeight={700}
              zIndex={100}
              px={3}
            >
              Framework Integrations
            </Text>

            <Stack px={3} spacing={0} pt={2}>
              {integrations.map(({ label, href, icon }) => (
                <NavItemTextOnly
                  key={label}
                  label={label}
                  href={href}
                  icon={icon}
                  py={'4px'}
                />
              ))}
              <Link href={''}>
                <Text
                  textStyle="paragraph-sm"
                  color="primary.500"
                  py={2}
                  fontWeight={700}
                >
                  See All
                  <Icon as={ArrowRightIcon} ml={2} />
                </Text>
              </Link>
            </Stack>
          </Box>

          <Box flex={1}>
            <Text
              w="100%"
              color={'gray.700'}
              _groupHover={{ color: 'primary.500' }}
              fontFamily={'heading'}
              fontWeight={700}
              zIndex={100}
              px={3}
            >
              Third Party Integrations
            </Text>

            <Stack px={3} spacing={0} pt={2}>
              {databases.map(({ label, href, icon }) => (
                <NavItemTextOnly
                  key={label}
                  label={label}
                  href={href}
                  icon={icon}
                  py={'4px'}
                />
              ))}
              <Link href={''}>
                <Text
                  textStyle="paragraph-sm"
                  color="primary.500"
                  py={2}
                  fontWeight={700}
                >
                  See All
                  <Icon as={ArrowRightIcon} ml={2} />
                </Text>
              </Link>
            </Stack>
          </Box>
        </Flex>
      </Stack>

      <Flex bg="#E4E9F2" flex={1} rounded="lg" p={4} flexDir={'column'} gap={6}>
        <Box flex={1}>
          <Text
            w="100%"
            color={'gray.700'}
            _groupHover={{ color: 'primary.500' }}
            fontFamily={'heading'}
            fontWeight={700}
            zIndex={100}
            px={3}
          >
            Learn
          </Text>

          <Stack px={3} spacing={0} pt={2}>
            {learn.map(({ label, href, icon }) => (
              <NavItemTextOnly
                key={label}
                label={label}
                href={href}
                icon={icon}
                py="4px"
              />
            ))}
          </Stack>
        </Box>
      </Flex>
    </Flex>
  );
}
