import * as React from 'react';

export function SocialSSOIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1.5A11.475 11.475 0 0112 9a11.475 11.475 0 01-3 7.5m0-15A11.475 11.475 0 006 9a11.475 11.475 0 003 7.5m0-15a7.5 7.5 0 100 15m0-15a7.5 7.5 0 010 15M1.875 6.75h14.25m-14.25 4.5h14.25"
        stroke="url(#prefix__paint0_linear_729_27321)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27321"
          x1={3.5}
          y1={1}
          x2={15.5}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
