import Link from 'next/link';
import { Box, Flex, Icon, LayoutProps, Text } from '@chakra-ui/react';
import { RemixiconReactIconComponentType } from 'remixicon-react';
import { ChevronRightIcon } from '@heroicons/react/outline';

type Props = {
  label: string;
  description?: string;
  icon: RemixiconReactIconComponentType;
  iconBoxBg?: string;
  iconColor?: string;
  labelColor?: string;
  iconSize?: LayoutProps['boxSize'];
  href?: string;
  scrolling?: boolean;
  overflow?: boolean;
  withArrow?: boolean;
};

export function NavItemWithIconMobile({
  label,
  description,
  icon,
  iconBoxBg,
  iconColor,
  labelColor,
  iconSize,
  href,
  scrolling,
  overflow,
  withArrow
}: Props) {
  return (
    <Link legacyBehavior href={href ? href : ''} passHref>
      <Flex
        as="a"
        align="center"
        gap={4}
        px={4}
        py={2}
        rounded="10px"
        role="group"
        transition="background .2s"
        _hover={{
          bg: 'gray.25'
        }}
        h="full"
        w="full"
        justifyContent={'space-between'}
      >
        <Box display="flex" flexDir="row" gap={4}>
          <Flex
            boxSize={'40px'}
            bg={iconBoxBg || 'gray.25'}
            rounded="md"
            transition="background .2s"
            _groupHover={{
              bg: !iconBoxBg && 'white',
              shadow: 'secondaryButtonNormal'
            }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Icon
              as={icon}
              boxSize={iconSize || 6}
              color={iconColor || 'gray.500'}
              _groupHover={{
                color: !iconColor && 'primary.500'
              }}
              pos="relative"
            />
          </Flex>
          <Box fontSize={'14px'} overflow={overflow ? 'visible' : 'hidden'}>
            <Text
              w="100%"
              color={labelColor ? labelColor : 'gray.700'}
              _groupHover={{ color: 'primary.500' }}
              fontFamily={'heading'}
              fontWeight={700}
              zIndex={100}
              whiteSpace={'nowrap'}
            >
              {label}
            </Text>
            {description && (
              <Box
                fontFamily={'heading'}
                zIndex={100}
                color="gray.500"
                flex={1}
              >
                <Text>{description}</Text>
              </Box>
            )}
          </Box>
        </Box>

        {withArrow && <Icon as={ChevronRightIcon} />}
      </Flex>
    </Link>
  );
}
