// textStyles cannot be used in custom theme overrides currently
// this is a reference of all the textStyles we use all over the app
// we will use this list once the following issue is fixed:
// https://github.com/chakra-ui/chakra-ui/issues/3884
// 'badge-sm': {
//   fontSize: 'xxs',
//   lineHeight: 'xxs',
//   fontWeight: 'semibold',
// },
// 'badge-md': {
//   fontSize: 'xs',
//   lineHeight: 'xs',
//   fontWeight: 'semibold',
// },

const fontConfig = {
  fontFeatureSettings:
    "'cv01' on, 'cv02' on, 'cv03' on, 'cv09' on, 'cv05' on, 'calt' off, 'liga' off, 'kern' off"
};

const xs = {
  fontSize: 'xs'
};

const sm = {
  fontSize: 'sm'
};

const md = {
  fontSize: 'md'
};

const lg = {
  fontSize: 'lg'
};

const xl = {
  fontSize: 'xl'
};

export const textStyles = {
  // New branding
  'label-lg': {
    ...fontConfig,
    fontSize: '1.125rem',
    letterSpacing: '-0.03em',
    lineHeight: '2rem',
    fontWeight: '500'
  },
  'label-md': {
    ...fontConfig,
    fontSize: '1rem',
    letterSpacing: '-0.03em',
    lineHeight: '1.75rem',
    fontWeight: '500'
  },
  'label-sm': {
    ...fontConfig,
    fontSize: '0.875rem',
    letterSpacing: '-0.02em',
    lineHeight: '1.5rem',
    fontWeight: '500'
  },
  'label-xs': {
    ...fontConfig,
    fontSize: '0.75rem',
    letterSpacing: '-0.03em',
    lineHeight: '1.25rem',
    fontWeight: '500'
  },

  'paragraph-lg': {
    ...fontConfig,
    fontSize: '1.125rem',
    letterSpacing: '-0.02em',
    lineHeight: '2rem'
  },
  'paragraph-md': {
    ...fontConfig,
    fontSize: '1rem',
    letterSpacing: '-0.01em',
    lineHeight: '1.75rem'
  },
  'paragraph-sm': {
    ...fontConfig,
    fontSize: '0.875rem',
    letterSpacing: '-0.02em',
    lineHeight: '1.5rem'
  },
  'paragraph-xs': {
    ...fontConfig,
    fontSize: '0.75rem',
    letterSpacing: '-0.02em',
    lineHeight: '1.25rem'
  },
  test: {
    ...fontConfig,
    fontSize: '14px',
    letterSpacing: '-0.02em',
    lineHeight: '1.25rem',
    fontFamily: 'heading',
    fontWeight: '100'
  },

  // Previous text styles
  'xs-normal': {
    ...xs,
    fontWeight: 'normal'
  },
  'xs-medium': {
    ...xs,
    fontWeight: 'medium'
  },
  'xs-semibold': {
    ...xs,
    fontWeight: 'semibold'
  },
  'sm-normal': {
    ...sm,
    fontWeight: 'normal'
  },
  'sm-medium': {
    ...sm,
    fontWeight: 'medium'
  },
  'md-normal': {
    ...md,
    fontWeight: 'normal'
  },
  'md-medium': {
    ...md,
    fontWeight: 'medium'
  },
  'md-semibold': {
    ...md,
    fontWeight: 'semibold'
  },
  'lg-normal': {
    ...lg,
    fontWeight: 'normal'
  },
  'lg-medium': {
    ...lg,
    fontWeight: 'medium'
  },
  'xl-normal': {
    ...xl,
    fontWeight: 'normal'
  },
  'xl-medium': {
    ...xl,
    fontWeight: 'medium'
  }
};
