import { useEffect } from 'react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Head from 'next/head';
import { ClerkProvider } from '@clerk/nextjs';
import { Box, ChakraProvider, LightMode } from '@chakra-ui/react';
import PlausibleProvider from 'next-plausible';
import TagManager from 'react-gtm-module';
import {
  BottomCTAV2,
  Fonts,
  Footer,
  Header,
  SentryLoader,
  SEO
} from '@components';
import {
  AppAndInstanceSwitcherProvider,
  DocsNavigationProvider,
  IntercomProvider
} from 'context';
import theme from '@theme';
import { analytics } from 'pages';
import 'focus-visible/dist/focus-visible'; // Remove Chakra's outline caused by clicks
import '@styles/index.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useSegmentIdentify } from '@hooks';
import { BannerProvider } from '@context/docs-banner';
import { RudderStackProvider } from '@hooks/RudderStackContext';
import DocslyClient from '@components/docs/docsly/docslyClient';
import { rudderPage } from '@hooks/rudderPage';

type PreviewNoticeProps = {
  preview: boolean;
};

function PreviewNotice({ preview }: PreviewNoticeProps) {
  if (!preview) {
    return null;
  }

  return (
    <div className="absolute top-0 w-full py-2 text-center bg-white border-b border-gray-200 z-[9999999]">
      This page is a preview.{' '}
      <Link legacyBehavior href="/api/exit-preview" passHref>
        <a className="underline transition-colors duration-200 hover:text-cyan">
          Click here
        </a>
      </Link>{' '}
      to exit preview mode.
    </div>
  );
}

const docsPages = [
  '/docs',
  '/docs/[category]/[...slug]',
  '/docs/[category]/overview',
  '/docs/[category]/[subcategory]/[...slug]'
];

const noBottomCTA = [
  ...docsPages,
  '/privacy',
  '/startups',
  '/terms',
  '/pricing'
];

const newGradientPages = [
  '/company',
  '/privacy',
  '/pricing',
  '/',
  '/components',
  '/blog',
  '/tutorials',
  '/render'
];

const blogPages = ['/blog', '/blog/[...slug]'];

const marketingLandingPage = ['/lp/[slug]'];

function MyApp({ Component, pageProps }: AppProps) {
  const { asPath, pathname } = useRouter();

  useEffect(() => {
    process.env.NEXT_PUBLIC_GTM_ID &&
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
        preview: process.env.NEXT_PUBLIC_GTM_PREVIEW
      });
  }, []);

  const UserIdentifyLoader = (): JSX.Element => {
    useSegmentIdentify();
    return <></>;
  };

  useEffect(() => {
    // Get name from SEO
    if (asPath === '/') {
      analytics.page(`Marketing_Home_Viewed`, {
        surface: `Marketing`,
        location: `Home`
      });
      rudderPage(`Marketing_Home_Viewed`, {
        title: `Home`,
        category: `Marketing`,
        surface: `Marketing`,
        location: `Home`
      });
    } else if (docsPages.includes(pathname)) {
      analytics.page(
        `Docs_${pageProps?.seo?.title}
        _Viewed`,
        {
          surface: `Docs`,
          location: pageProps?.seo?.title
        }
      );
      rudderPage(`${pageProps?.seo?.title}`, {
        title: `${pageProps?.seo?.title}`,
        category: `Docs`,
        surface: `Docs`,
        location: pageProps?.seo?.title
      });
    } else if (blogPages.includes(pathname)) {
      analytics.page(
        `Blog_${pageProps?.seo?.title}
        _Viewed`,
        {
          surface: `Blog`,
          location: pageProps?.seo?.title
        }
      );
      rudderPage(`${pageProps?.seo?.title}`, {
        title: `${pageProps?.seo?.title}`,
        category: `Blog`,
        surface: `Blog`,
        location: pageProps?.seo?.title
      });
    } else {
      analytics.page(`${pageProps?.seo?.title}_Viewed`, {
        surface: `Marketing`,
        location: pageProps?.seo?.title
      });
      rudderPage(`${pageProps?.seo?.title}`, {
        title: `${pageProps?.seo?.title}`,
        category: `Marketing`,
        surface: `Marketing`,
        location: pageProps?.seo?.title
      });
    }
  }, [asPath]);

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
          key="apple-touch-icon"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
          key="favicon-32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
          key="favicon-16"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" key="manifest" />
        <meta name="theme-color" content="#170f3e" key="theme" />
        <meta name="msapplication-TileColor" content="#170f3e" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#170f3e"
        />
        <SentryLoader />
      </Head>

      <Fonts />

      <ClerkProvider>
        <SEO {...pageProps} />
        <PreviewNotice {...pageProps} />
        <IntercomProvider>
          <PlausibleProvider domain={process.env.NEXT_PUBLIC_SITE_URL || ''}>
            <RudderStackProvider>
              <ChakraProvider theme={theme}>
                {typeof window !== undefined && <UserIdentifyLoader />}
                <DocsNavigationProvider>
                  <BannerProvider>
                    {docsPages.includes(pathname) ? (
                      <AppAndInstanceSwitcherProvider>
                        <Box overflowX="hidden" mb="-25px">
                          <Component {...pageProps} />
                          <DocslyClient />
                        </Box>
                      </AppAndInstanceSwitcherProvider>
                    ) : (
                      <>
                        {/* Prevent non-docs pages from using dark mode */}

                        <LightMode>
                          {marketingLandingPage.includes(pathname) ? (
                            <Component {...pageProps} />
                          ) : (
                            <>
                              <Header />

                              <Box
                                overflowX="hidden"
                                mb="-25px"
                                pt={
                                  newGradientPages.includes(pathname)
                                    ? '0px'
                                    : pathname.includes('/blog/')
                                    ? { sm: '10px', md: '40px' }
                                    : '80px'
                                }
                              >
                                <Component {...pageProps} />
                                {!noBottomCTA.includes(pathname) ? (
                                  <BottomCTAV2 />
                                ) : (
                                  <Footer />
                                )}
                              </Box>
                            </>
                          )}
                        </LightMode>
                      </>
                    )}
                  </BannerProvider>
                </DocsNavigationProvider>
              </ChakraProvider>
            </RudderStackProvider>
          </PlausibleProvider>
        </IntercomProvider>
      </ClerkProvider>
    </>
  );
}

export default MyApp;
