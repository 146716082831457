export function APIIcon(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 11C19 15.4183 15.4183 19 11 19M19 11C19 6.58172 15.4183 3 11 3M19 11H3M11 19C6.58172 19 3 15.4183 3 11M11 19C13.001 16.8093 14.1388 13.9664 14.2006 11C14.1388 8.03363 13.001 5.19068 11 3M11 19C8.99898 16.8093 7.86235 13.9664 7.80055 11C7.86235 8.03363 8.99898 5.19068 11 3M3 11C3 6.58172 6.58172 3 11 3M5 19C5 20.1046 4.10457 21 3 21C1.89543 21 1 20.1046 1 19C1 17.8954 1.89543 17 3 17C4.10457 17 5 17.8954 5 19ZM21 19C21 20.1046 20.1046 21 19 21C17.8954 21 17 20.1046 17 19C17 17.8954 17.8954 17 19 17C20.1046 17 21 17.8954 21 19ZM5 3C5 4.10457 4.10457 5 3 5C1.89543 5 1 4.10457 1 3C1 1.89543 1.89543 1 3 1C4.10457 1 5 1.89543 5 3ZM21 3C21 4.10457 20.1046 5 19 5C17.8954 5 17 4.10457 17 3C17 1.89543 17.8954 1 19 1C20.1046 1 21 1.89543 21 3Z"
        stroke="url(#paint0_linear_1623_932)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1623_932"
          x1="1"
          y1="1"
          x2="21"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset="1" stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
