import Link from 'next/link';
import { Box, Flex, Icon, LayoutProps, Text } from '@chakra-ui/react';

type Props = {
  label: string;
  description?: string;
  icon: any;
  iconBoxBg?: string;
  iconColor?: string;
  labelColor?: string;
  iconSize?: LayoutProps['boxSize'];
  href?: string;
  noHover?: boolean;
};

export function NavItemWithIcon({
  label,
  description,
  icon,
  iconBoxBg,
  iconColor,
  labelColor,
  iconSize,
  href,
  noHover
}: Props) {
  return (
    <Link href={href ? href : ''} passHref>
      <Flex
        as="a"
        flexDir="row"
        alignItems="center"
        justifyContent={'flex-start'}
        gap={4}
        px={4}
        py={2}
        rounded="10px"
        role="group"
        transition="background .2s"
        _hover={{
          bg: 'gray.25'
        }}
        minW="255px"
        w="full"
      >
        <Flex
          boxSize={'40px'}
          bg={iconBoxBg || 'gray.25'}
          rounded="md"
          transition="background .2s"
          _groupHover={{
            bg: !noHover ? 'white' : iconBoxBg,
            shadow: 'secondaryButtonNormal'
          }}
          justifyContent={'center'}
          alignItems={'center'}
          minW="40px"
        >
          <Icon
            as={icon}
            boxSize={iconSize || 6}
            color={iconColor || 'gray.500'}
            _groupHover={{
              bg: !noHover ? 'white' : iconBoxBg,
              color: !iconColor && 'primary.500'
            }}
            pos="relative"
          />
        </Flex>

        <Box fontSize={'14px'} pr="12px" position="relative" zIndex={1}>
          <Flex flexDir="column">
            <Text
              fontFamily="body"
              fontStyle="normal"
              fontWeight={700}
              fontSize={14}
              lineHeight="24px"
              letterSpacing="-0.02em"
              flex="none"
              order={0}
              flexGrow={0}
              whiteSpace="nowrap"
              color={labelColor ? labelColor : '#262F40'}
              _groupHover={{ color: 'primary.500' }}
            >
              {label}
            </Text>
            {description && (
              <Box
                fontFamily="body"
                fontStyle="normal"
                fontWeight={400}
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="-0.02em"
                color="#626B7F"
                flex="none"
                order={1}
                flexGrow={0}
                whiteSpace="nowrap"
              >
                <Text>{description}</Text>
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
    </Link>
  );
}
