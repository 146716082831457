import React, { useEffect, useState } from 'react';
import '@docsly/react/dist/index.css';
import { usePathname } from 'next/navigation';
import { useAuth } from '@clerk/nextjs';
import Docsly from '@docsly/react';

function DocslyAuth({
  docslyPublicId,
  pathname
}: {
  docslyPublicId: string;
  pathname: string;
}) {
  const { getToken } = useAuth();
  const [userToken, setUserToken] = useState('');

  useEffect(() => {
    const getUserToken = async () => {
      const token = await getToken({ template: 'docsly' });
      if (token) {
        setUserToken(token);
      }
    };

    getUserToken();
  }, [getToken]);

  return (
    <Docsly
      publicId={docslyPublicId}
      pathname={pathname as string}
      getUserToken={() => Promise.resolve(userToken)}
    />
  );
}

export default function DocslyClient() {
  const pathname = usePathname();
  if (!pathname) return null;
  const docslyPublicId = process.env.NEXT_PUBLIC_DOCSLY_ID;
  if (!docslyPublicId) throw new Error('NEXT_PUBLIC_DOCSLY_ID is not set');

  return <DocslyAuth docslyPublicId={docslyPublicId} pathname={pathname} />;
}
