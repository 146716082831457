import { ComponentSelectButtons, DescriptionBlock } from './page-components';
import React, { SVGProps } from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  chakra,
  Heading,
  Button,
  useMediaQuery,
  HStack,
  Icon
} from '@chakra-ui/react';
import { ImageLoader } from './image-loader';
import { CodeWindow } from '@components/common';
import Image from 'next/image';
import { motion, isValidMotionProp, useAnimation } from 'framer-motion';
import { FrameworkSwitcher } from './auth-user-components';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { BookOpenIcon } from '@heroicons/react/outline';

type Component = {
  name: string;
  description: string;
  icon: any;
  image: string;
  height?: number;
  width?: number;
};

interface CodePreviewProps {
  componentList: Component[];
  setCurrentComponent: (currentComponent: Component) => void;
  currentComponent: Component;
  currentFramework: string;
  setCurrentFramework: (currentFramework: string) => void;
  snippet: string | undefined;
  header: string;
  subheader: string;
  imgDirection: string;
  icon: string;
}

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children'
});

export function CodePreview({
  componentList,
  setCurrentComponent,
  currentComponent,
  currentFramework,
  setCurrentFramework,
  snippet,
  header,
  subheader,
  imgDirection,
  icon
}: CodePreviewProps): JSX.Element {
  const [showCode, setShowCode] = React.useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] = React.useState<any>();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const { pathname } = useRouter();

  const controls = useAnimation();

  const [isSmallerThan1330] = useMediaQuery('(max-width: 1330px)');

  const slideComponentStart = () => {
    if (!showCode) {
      switch (currentComponent?.name) {
        case '<OrganizationProfile/>':
          controls.start({
            x: '30%',
            y: '30%',
            transition: {
              duration: 0.25,
              type: 'tween'
            },
            scale: [0.8, 0.6]
          });
          break;
        case '<UserProfile/>':
          controls.start({
            x: '30%',
            y: '30%',
            transition: {
              duration: 0.25,
              type: 'tween'
            },
            scale: [0.8, 0.6]
          });
          break;
        case '<UserButton/>':
          controls.start({
            x: '4%',
            y: '18%',
            transition: {
              duration: 0.25,
              type: 'tween'
            },
            scale: [0.8, 0.6]
          });
          break;
        case '<OrganizationSwitcher/>':
          controls.start({
            x: '4%',
            y: '40%',
            transition: {
              duration: 0.25,
              type: 'tween'
            },
            scale: [0.8, 0.8]
          });
          break;
        default:
          controls.start({
            x: '30%',
            y: '30%',
            transition: {
              duration: 0.25,
              type: 'tween'
            },
            scale: [0.8, 0.8]
          });
          setLoaded(true);
      }
    }
  };

  const slideComponentReturn = () => {
    switch (currentComponent?.name) {
      case '<OrganizationProfile/>':
        controls.start({
          x: '0%',
          y: '0%',
          transition: {
            duration: 0.25,
            type: 'tween'
          },
          scale: [0.6, 0.8]
        });
        break;
      case '<UserProfile/>':
        controls.start({
          x: '0%',
          y: '0%',
          transition: {
            duration: 0.25,
            type: 'tween'
          },
          scale: [0.6, 0.8]
        });
        break;
      default:
        controls.start({
          x: '0%',
          y: '0%',
          transition: {
            duration: 0.25,
            type: 'tween'
          },
          scale: [0.8, 0.8]
        });
    }
  };

  const sequence = async (component: any) => {
    await controls.start({
      opacity: ['100%', '0%'],
      transition: {
        duration: 0.125,
        type: 'tween'
      }
    });
    await setCurrentComponent(component);
    switch (component?.name) {
      case '<UserButton/>':
        await controls.start({
          x: '4%',
          y: '18%',
          opacity: '0%',
          transition: {
            duration: 0.125,
            type: 'tween'
          },
          scale: [0.6, 0.6]
        });
        break;
      case '<UserProfile/>':
        await controls.start({
          x: '30%',
          y: '30%',
          opacity: '0%',
          transition: {
            duration: 0.125,
            type: 'tween'
          },
          scale: [0.8, 0.6]
        });
        break;
      case '<SignUp/>':
        await controls.start({
          x: '25%',
          y: '30%',
          opacity: '0%',
          transition: {
            duration: 0.125,
            type: 'tween'
          },
          scale: [0.8, 0.8]
        });
        break;
      case '<OrganizationProfile/>':
        await controls.start({
          x: '15%',
          y: '50%',
          opacity: '0%',
          transition: {
            duration: 0.125,
            type: 'tween'
          },
          scale: [0.8, 0.8]
        });
        break;
      case '<OrganizationSwitcher/>':
        await controls.start({
          x: '0%',
          y: '40%',
          opacity: '0%',
          transition: {
            duration: 0.125,
            type: 'tween'
          },
          scale: [0.8, 0.8]
        });
        break;
      default:
        await controls.start({
          x: '25%',
          y: '30%',
          opacity: '0%',
          transition: {
            duration: 0.125,
            type: 'tween'
          },
          scale: [0.8, 0.8]
        });
    }

    await controls.start({
      opacity: ['0%', '100%'],
      transition: {
        duration: 0.125,
        type: 'tween'
      }
    });
  };

  const handleOnClick = async (component: any) => {
    if (showCode) {
      sequence(component);
    } else {
      setCurrentComponent(component);
    }
  };

  const leftAlign = () => {
    switch (currentComponent?.name) {
      case '<OrganizationSwitcher/>':
        return -18;
        break;
      case '<OrganizationProfile/>':
        return 0;
        break;
      default:
        return '-2%';
    }
  };

  return (
    <>
      {/* Desktop View */}
      <Box display={isSmallerThan1330 ? 'none' : 'flex'}>
        <Stack
          pos="relative"
          flexDir={imgDirection === 'right' ? 'row' : 'row-reverse'}
          color={pathname === '/' ? 'white' : 'inherit'}
          w="90vw"
          justifyContent={'space-between'}
        >
          <Box pos="relative">
            <Flex
              justifyContent={'flex-start'}
              flexDir="column"
              alignItems={'flex-start'}
              maxW={'470px'}
            >
              <Box mt={-4} mb={4}>
                <Image src={icon} width={72} height={72} alt="" />
              </Box>
              <Heading
                as="h3"
                size="h3"
                pt="0px"
                pb="16px"
                textAlign={'left'}
                color={pathname === '/' ? 'white' : 'inherit'}
              >
                {header}
              </Heading>
              <Text
                textAlign={'left'}
                fontSize={'24px'}
                fontFamily={'heading'}
                pb="30px"
              >
                {subheader}
              </Text>
            </Flex>
            <Box display={'flex'}>
              <DescriptionBlock
                name={currentComponent?.name}
                icon={currentComponent?.icon}
                description={currentComponent?.description}
              />
            </Box>
          </Box>

          <Flex flexDir={'column'}>
            <ComponentSelectButtons
              componentList={componentList}
              currentComponent={currentComponent}
              handleOnClick={handleOnClick}
            />

            {showCode ? (
              <>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignContent="center"
                  pos="relative"
                  width={704}
                  my="36px"
                  height={397}
                >
                  <CodeWindow
                    snippet={snippet}
                    tabs={
                      <FrameworkSwitcher
                        currentFramework={currentFramework}
                        setCurrentFramework={setCurrentFramework}
                      />
                    }
                  />
                </Box>
                <Box pos="relative" top={-810} left={10} display={'flex'}>
                  <Button
                    zIndex={100}
                    rounded="full"
                    alignSelf="center"
                    py="24px"
                    px="13px"
                    height="20px"
                    fontWeight={700}
                    pos="absolute"
                    top={710}
                    onClick={() => {
                      {
                        !showCode
                          ? slideComponentStart()
                          : slideComponentReturn();
                      }
                      setShowCode(!showCode);
                    }}
                  >
                    {showCode ? 'Hide code' : 'View code'}
                  </Button>
                </Box>
              </>
            ) : (
              <Box pt="24px" pos="relative">
                <Box
                  pos="relative"
                  top={4}
                  width={704}
                  height={445}
                  display={'flex'}
                >
                  <Image
                    src="/images/components/themes/desktop/browser-background.svg"
                    alt=""
                    fill
                  />
                </Box>

                <Box pos="relative" top={-810} left={10} display={'flex'}>
                  <Button
                    zIndex={100}
                    rounded="full"
                    alignSelf="center"
                    py="24px"
                    px="13px"
                    height="20px"
                    fontWeight={700}
                    pos="absolute"
                    top={710}
                    onClick={() => {
                      {
                        !showCode
                          ? slideComponentStart()
                          : slideComponentReturn();
                      }
                      setShowCode(!showCode);
                    }}
                  >
                    {showCode ? 'Hide code' : 'View code'}
                  </Button>
                </Box>
              </Box>
            )}
            <ChakraBox
              id="component"
              as={motion.div}
              animate={controls}
              width={'65%'}
              height={'full'}
              top={currentComponent?.name === '<UserButton/>' ? 14 : 8}
              pos="absolute"
              right={
                imgDirection === 'right' &&
                currentComponent.name == '<UserButton/>'
                  ? '-18%'
                  : '-5%'
              }
              left={imgDirection === 'left' ? leftAlign() : 'auto'}
              transform={`scale(0.8)`}
              zIndex={10}
            >
              <ImageLoader currentComponent={currentComponent} />
            </ChakraBox>
          </Flex>
        </Stack>
      </Box>

      {/* Mobile view */}
      <Box display={!isSmallerThan1330 ? 'none' : 'flex'} w="100%">
        <Stack
          pos="relative"
          flexDir="column"
          justifyContent={'center'}
          alignItems="center"
          w="full"
          color={pathname === '/' ? 'white' : 'inherit'}
        >
          <Box pos="relative" w="100%">
            <Flex
              justifyContent={'center'}
              flexDir="column"
              alignItems={'center'}
            >
              {' '}
              <Box>
                <Image src={icon} width={72} height={72} alt="" />
              </Box>
              <Heading
                as="h3"
                size="h3"
                pt="24px"
                pb="16px"
                textAlign={'center'}
                px={'16px'}
                maxW={{ base: '60%', md: '100%' }}
                color={pathname === '/' ? 'white' : 'inherit'}
              >
                {header}
              </Heading>
              <Text
                textAlign={'center'}
                fontSize={'16px'}
                fontFamily={'heading'}
                pb="30px"
                maxW="90vw"
              >
                {subheader}
              </Text>
            </Flex>
          </Box>

          <Flex
            flexDir={'column'}
            w="full"
            justifyContent={'center'}
            alignItems="center"
          >
            <Box w={{ base: '86%', xl: '60%' }}>
              <ComponentSelectButtons
                componentList={componentList}
                currentComponent={currentComponent}
                handleOnClick={handleOnClick}
              />
            </Box>

            {showCode ? (
              <Box
                width={359}
                height={570}
                display="flex"
                justifyContent="center"
                alignItems={'center'}
              >
                <Box
                  py="24px"
                  pos="relative"
                  justifySelf="center"
                  width={320}
                  height={493}
                >
                  <CodeWindow
                    snippet={snippet}
                    tabs={
                      <Box>
                        <FrameworkSwitcher
                          currentFramework={currentFramework}
                          setCurrentFramework={setCurrentFramework}
                        />
                      </Box>
                    }
                  />
                  <Box
                    w={320}
                    h={100}
                    bg="transparent"
                    pos="relative"
                    top={-100}
                    rounded="xl"
                    bgGradient="linear(to-b, transparent 0%,  #0F0839 50%)"
                  ></Box>
                </Box>
              </Box>
            ) : (
              <ChakraBox
                id="component"
                as={motion.div}
                animate={controls}
                pt="24px"
                pos="relative"
                justifySelf="center"
              >
                <Box pos="relative" top={4} width={359} height={546}>
                  <Image
                    src="/images/mobile-browser-background.svg"
                    alt=""
                    fill
                  />
                </Box>
                <Box
                  zIndex={10}
                  id="component"
                  right={
                    currentComponent?.name == '<OrganizationSwitcher/>'
                      ? '35%'
                      : ''
                  }
                  top={
                    currentComponent?.name == '<OrganizationSwitcher/>' ||
                    currentComponent?.name == '<OrganizationProfile/>'
                      ? '20%'
                      : currentComponent?.name == '<SignUp/>'
                      ? '5%'
                      : currentComponent?.name == '<UserProfile/>'
                      ? '25%'
                      : '10%'
                  }
                  w="full"
                  h="auto"
                  pos="absolute"
                  transform={
                    currentComponent?.name == '<OrganizationSwitcher/>'
                      ? 'scale(1.6)'
                      : currentComponent?.name == '<OrganizationProfile/>'
                      ? 'scale(1)'
                      : 'scale(.8)'
                  }
                  overflow="visible"
                >
                  <Image
                    src={currentComponent?.image}
                    alt={currentComponent?.name}
                    width={379}
                    height={453}
                  />
                </Box>
              </ChakraBox>
            )}
            <Box
              pos="relative"
              display="flex"
              top={-830}
              justifyContent="center"
              w="full"
              alignContent="center"
            >
              <Button
                zIndex={100}
                rounded="full"
                alignSelf="center"
                py="24px"
                px="13px"
                height="20px"
                lineHeight={'24px'}
                fontWeight={700}
                pos="absolute"
                top={700}
                onClick={() => {
                  setShowCode(!showCode);
                }}
              >
                {showCode ? 'Hide code' : 'View code'}
              </Button>
            </Box>
          </Flex>
          <Flex
            w="full"
            justifyContent={'center'}
            alignItems="center"
            flexDir={'column'}
          >
            <DescriptionBlock
              name={currentComponent?.name}
              icon={currentComponent?.icon}
              description={currentComponent?.description}
            />
          </Flex>
        </Stack>
      </Box>
    </>
  );
}
