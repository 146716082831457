import * as React from 'react';

export function CreateOrgIcon(props) {
  return (
    <svg
      width={18}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.625 4.25h2.063m-2.063 3h2.063m-2.063 3h2.063m2.625-6h2.062m-2.063 3h2.063m-2.063 3h2.063M15 14.75V3.65c0-.84 0-1.26-.164-1.581a1.5 1.5 0 00-.655-.656c-.32-.163-.74-.163-1.581-.163H5.4c-.84 0-1.26 0-1.581.163a1.5 1.5 0 00-.656.656C3 2.389 3 2.809 3 3.65v11.1m13.5 0h-15"
        stroke="url(#prefix__paint0_linear_729_27323)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27323"
          x1={2}
          y1={1}
          x2={17}
          y2={16.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
