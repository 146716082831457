export function SecurityIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        stroke="url(#prefix__paint0_linear_729_27328)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.299 9.896c-.053-.159-.08-.238-.078-.303a.317.317 0 01.042-.165c.03-.057.11-.132.27-.28a2.25 2.25 0 10-3.065 0c.16.148.24.223.27.28.03.06.04.097.041.165.002.065-.025.144-.078.303l-.688 2.064c-.089.267-.133.4-.106.506a.375.375 0 00.162.225c.092.059.232.059.513.059h2.836c.28 0 .421 0 .513-.059a.375.375 0 00.162-.225c.027-.106-.017-.24-.106-.506l-.688-2.064z"
        stroke="url(#prefix__paint1_linear_729_27328)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_729_27328"
          x1={4.5}
          y1={1}
          x2={14.5}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_729_27328"
          x1={4.5}
          y1={1}
          x2={14.5}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C47FF" />
          <stop offset={1} stopColor="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
