import { Center, Box, Link, Flex, Text, Icon } from '@chakra-ui/react';
import { APIIcon } from '@components/common';
import { ChevronRightIcon } from '@heroicons/react/outline';

const CustomFlowsComponent = () => {
  return (
    <Center
      boxSizing="border-box"
      display="flex"
      padding="6px 9px 6px 13px"
      width="fit-content"
      height="57px"
      background="#F7F9FC"
      border="1px solid #E4E9F2"
      borderRadius="8px"
      _hover={{ boxShadow: '3px 4px 10px rgba(57, 67, 86, 0.1)' }}
    >
      <Link href="/docs/authentication/custom-flows">
        <Flex
          as="a"
          align="center"
          rounded="10px"
          role="group"
          transition="background .2s"
          _hover={{
            bg: 'gray.25'
          }}
          w="full"
          justifyContent="space-between"
          gap={4}
        >
          <Box
            display="flex"
            bg="gray.25"
            rounded="md"
            transition="background .2s"
            _groupHover={{
              bg: 'white',
              shadow: 'secondaryButtonNormal'
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              as={APIIcon}
              boxSize="24px"
              color={
                'linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)' ||
                'gray.500'
              }
              _groupHover={{
                color:
                  !'linear-gradient(271.19deg, #17CCFC -28.89%, #6C47FF 80.53%)' &&
                  'primary.500'
              }}
              pos="relative"
            />
          </Box>
          <Box
            fontSize="14px"
            display={'flex'}
            flexDir="row"
            alignItems={'center'}
            gap={2}
          >
            <Text
              w="100%"
              color="gray.700"
              _groupHover={{ color: 'primary.500' }}
              fontFamily="body"
              fontSize="14px"
              fontWeight={700}
              zIndex={100}
              whiteSpace="nowrap"
            >
              Custom flows
            </Text>

            <Text
              fontFamily="body"
              color="gray.500"
              flex={1}
              fontSize="12px"
              whiteSpace="nowrap"
            >
              APIs, SDKs, and React hooks to fully customize your authentication
              flow.
            </Text>
          </Box>

          <Icon as={ChevronRightIcon} />
        </Flex>
      </Link>
    </Center>
  );
};

export default CustomFlowsComponent;
