import * as React from 'react';

export function OrgSwitcherIcon(props) {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 14.5715H3M3 14.5715L6 11.143M3 14.5715L6 18.0001M3 6.0001H15M15 6.0001L12 2.57153M15 6.0001L12 9.42868"
        stroke="url(#paint0_linear_1842_29706)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1842_29706"
          x1="1.5"
          y1="3"
          x2="15.4073"
          y2="17.6123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6C47FF" />
          <stop offset="1" stop-color="#17CCFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
