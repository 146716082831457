import { Text, Stack, Heading } from '@chakra-ui/react';

import { NavItemTextOnly } from '../nav-item-text-only';

export function CompanyMobile(): JSX.Element {
  return (
    <Stack w="100vw" pos="relative" px={6}>
      <NavItemTextOnly label={'About Us'} href={'/about'} hover />
      <NavItemTextOnly label={'Blog'} href={'/blog'} hover />
      <NavItemTextOnly
        label={'Open Source'}
        href={'https://github.com/clerkinc'}
        hover
      />
      <NavItemTextOnly label={'Privacy Trust Center'} href={'/privacy'} hover />
      <NavItemTextOnly
        label={`Careers (we're hiring!)`}
        href={'/career'}
        hover
      />
    </Stack>
  );
}
